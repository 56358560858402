<script>
import PEI_Header from "../molecules/PEI_Header.svelte";
import PEI_Footer from "../atoms/PEI_Footer.svelte";
import {store} from "../../store/store";

export let id;
export let state;

const account = store.account$;
</script>

<section class="popup" vbox>
	<PEI_Header {state}>
        {#if $account}
			<div>{$account.team}
				<span color="highlight-secondary"><strong>{$account.name}</strong> {$account.job}</span>
			</div>
        {/if}
	</PEI_Header>

	<div flex>
		<slot/>
	</div>

	<PEI_Footer/>
</section>

<style type="text/stylus">
.popup
	//layer()
	background $white
</style>