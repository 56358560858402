<script>
import {dispatch} from "1px";
import {_LOGOUT} from "../../store/features/account/store.account.js";
import UIActivity from "../../components/ui/UIPage.svelte";
import {_GO} from "../../1px/plugins/router.js";

import Icon__project from "../../components/atoms/icons/Icon__project.svelte";
import Icon__schedule from "../../components/atoms/icons/Icon__schedule.svelte";
import Icon__notice from "../../components/atoms/icons/Icon__notice.svelte";
import Icon__account from "../../components/atoms/icons/Icon__account.svelte";
import Icon__logout from "../../components/atoms/icons/Icon__logout.svelte";
import PEI_LayoutPopup from "../../components/organisms/PEI_LayoutPopup.svelte";
import UIPage from "../../components/ui/UIPage.svelte";

const go = (url) => dispatch(_GO(url));
const Logout = () => dispatch(_LOGOUT());
</script>


<UIPage {...$$props}>
	<PEI_LayoutPopup state="active">
		<div class="nav__row" on:click={() => go("/projects")}>
			<icon class="row__icon">
				<Icon__project type="big" color="black"/>
			</icon>
			<h1 class="row__title">project</h1>
		</div>

		<div class="nav__row" on:click={() => go("/schedules")}>
			<icon class="row__icon">
				<Icon__schedule type="big" color="black"/>
			</icon>
			<h1 class="row__title">schedule</h1>
		</div>

<!--		<div class="nav__row" on:click={() => go("/calendar")}>-->
<!--			<icon class="row__icon">-->
<!--				<Icon__schedule type="big" color="black"/>-->
<!--			</icon>-->
<!--			<h1 class="row__title">calendar</h1>-->
<!--		</div>-->

		<div class="nav__row" on:click={() => go("/notices")}>
			<icon class="row__icon">
				<Icon__notice type="big" color="black"/>
			</icon>
			<h1 class="row__title">notice</h1>
		</div>

		<div class="nav__row" primary on:click={() => go("/my")}>
			<icon class="row__icon">
				<Icon__account color/>
			</icon>
			<h1 class="row__title">My account</h1>
		</div>

		<div class="nav__row" secondary on:click={Logout}>
			<icon class="row__icon">
				<Icon__logout/>
			</icon>
			<h1 class="row__title">Logout</h1>
		</div>

	</PEI_LayoutPopup>
</UIPage>


<style type="text/stylus">
.nav__row
	display block
	height 5.2rem
	padding 0 1.4rem
	border-bottom 1px solid #999
	cursor pointer
	hbox()

	.row__icon
		width 2rem
		text-align center

	.row__title
		font-size 2rem
		text-transform uppercase
		margin-left .8rem


.nav__row:hover
.nav__row:active
	background rgba(0, 0, 0, .1)
</style>