<script>
import {createDateFormat} from "../../1px/fp/date.js";

export let value;
$: value = value || +new Date();

const updateFrom = (time) => {
	const ret = new Date(value);
	const [hour, minutes] = time.split(":");

	ret.setHours(hour)
	ret.setMinutes(minutes)
	ret.setSeconds(0)
	ret.setMilliseconds(0)
	return +ret;
}

const format = createDateFormat("hh:ii")

let time = format(value);
$: value = updateFrom(time);
</script>

<input type="time" bind:value={time}/>