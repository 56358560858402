<script>
export let color;
</script>

<svg class="icon__account" viewBox="0 0 18 18" {color}>
	<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
		<g id="source" transform="translate(-203.000000, -75.000000)">
			<g id="icon-account" transform="translate(200.000000, 72.000000)">
				<polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
				<path d="M3,5 L3,19 C3,20.1 3.89,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,5 C21,3.9 20.1,3 19,3 L5,3 C3.89,3 3,3.9 3,5 Z M15,9 C15,10.66 13.66,12 12,12 C10.34,12 9,10.66 9,9 C9,7.34 10.34,6 12,6 C13.66,6 15,7.34 15,9 Z M6,17 C6,15 10,13.9 12,13.9 C14,13.9 18,15 18,17 L18,18 L6,18 L6,17 Z"
					  id="Shape" fill="#009BE0" fill-rule="nonzero"></path>
			</g>
		</g>
	</g>
</svg>

<style type="text/stylus">
svg.icon__account
	width 1.8rem
	height 1.8rem

svg.icon__account[color='white']
	width 1.6rem
	height 1.6rem

	path
		fill $white
</style>