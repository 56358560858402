<script>
export let field;
export let status = null;
export let theme = null;
export let value = null;
export let error = null;
</script>


<div class="list__row" class:error={$error} {status}>
	<div class="row__field">{field}</div>
	<div class="row__value">
		<div class="value__text" {theme}>
			<slot/>
		</div>
	</div>
</div>


<style type="text/stylus">
.list__row
	min-height 3.6rem
	font-size 1.4rem
	border-bottom 1px dashed $light-gray
	hbox()

	.row__field
		width 9rem
		font-weight bold
		margin-left 1.2rem

	.row__value
		padding-right 1.2rem
		flex()

		.value__text
			padding 0 .4rem

			&[theme='bold']
				font-weight bold

			&[theme='primary']
				color $primary

			&[theme='secondary']
				font-weight bold
				color $secondary


	&[status='editable']
		.value__text
			height 3rem
			padding 0 .4rem
			border 1px solid $light-gray
			border-radius 3px

	&.error
		.value__text
			border-color $secondary

.list__row:last-of-type
	border-bottom 0

</style>