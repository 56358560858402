<script>
export let status;
export let placeholder;
export let value;
export let input_type;
export let error
</script>

<pei-inputbox status={status} class:error={$error}>
  {#if input_type == 'text'}
    <input class="inputbox__input" type="text" placeholder={placeholder} bind:value={value}/>
  {:else if input_type == 'password'}
    <input class="inputbox__input" type="password" placeholder={placeholder} bind:value={value}/>
  {/if}
</pei-inputbox>


<style type="text/stylus">
pei-inputbox
  display block
  position relative
  height 3.6rem
  font-size 1.4rem
  border 1px solid $black
  border-radius .5rem
  padding 0 1.2rem

  &.error
    border-color $secondary

  :global(& + &)
    margin-top .8rem

  .inputbox__input
    width 100%
    height 100%
    border 0
    padding 0 .4rem
    text-align center

    &::placeholder
      color $light-gray

</style>