/// 액션
import {action} from "../1px";
import {createDateFormat} from "../1px/fp/date.js";

const dateFormat = createDateFormat("yyyy-mm-dd");

/// 액션 생성자
export const _FIND_PASSWORD = action("FIND_PASSWORD");
export const _RESET_PASSWORD = action("RESET_PASSWORD");

export const _MY_ACCOUNT = action("MY_ACCOUNT");

export const _새_프로젝트_추가하기 = action("_새_프로젝트_추가하기");
export const _프로젝트_상세페이지로_이동 = action("_프로젝트_상세페이지로_이동");
export const _프로젝트_편집가능_상태확인하기 = action("_프로젝트_편집가능_상태확인하기");
export const _프로젝트_편집하기 = action("_프로젝트_편집하기");
export const _프로젝트_삭제하기 = action("_프로젝트_삭제하기");
export const _MORE_PROJECTS = action("_MORE_PROJECTS");

export const _프로젝트_저장하기 = action("_프로젝트_저장하기");
export const _프로젝트_편집_취소하기 = action("_프로젝트_편집_취소하기");


export const _새_스케줄_추가하기 = action("_새_스케줄_추가하기(start_datetime)", (start_datetime) => start_datetime || dateFormat());
export const _스케줄_상세보기 = action("_스케줄_상세보기");
export const _스케줄_편집하기 = action("_스케줄_편집하기");
export const _스케줄_삭제하기 = action("_스케줄_삭제하기");
export const _스케줄_편집가능_상태확인하기 = action("_스케줄_편집가능_상태확인하기");
export const _MORE_SCHEDULE = action("_MORE_SCHEDULE");
export const _권한_확인하기 = action("_권한_확인하기");

export const _스케줄_저장하기 = action("_스케줄_저장하기");
export const _스케줄_편집_취소하기 = action("_스케줄_편집_취소하기");


/// 공지사항
export const _새_공지사항_추가하기 = action("_새_공지사항_추가하기");
export const _공지사항_불러오기 = action("_공지사항_불러오기");
export const _공지사항_상세_불러오기 = action("_공지사항_상세_불러오기", id => ({id}));
export const _공지사항_필터적용하기 = action("_공지사항_필터적용하기");
export const _공지사항_검색하기 = action("_공지사항_검색하기");
export const _공지사항_상세페이지로_이동 = action("_공지사항_상세페이지로_이동");
export const _공지사항_편집하기 = action("_공지사항_편집하기");
export const _공지사항_삭제하기 = action("_공지사항_삭제하기");
export const _공지사항_편집가능_상태확인하기 = action("_공지사항_편집가능_상태확인하기");
export const _공지사항_저장하기 = action("_공지사항_저장하기");
export const _공지사항_편집_취소하기 = action("_공지사항_편집_취소하기");
export const _MORE_NOTICE = action("MORE_NOTICE");


///유저
export const _SAVE_MYACCOUNT = action("SAVE_MYACCOUNT");
export const _CANCEL_MYACCOUNT = action("CANCEL_MYACCOUNT");
export const _내정보_편집하기 = action("_내정보_편집하기");

export const _유저_검색하기 = action("_유저_검색하기");
export const _유저_상세페이지로_이동 = action("_유저_상세페이지로_이동");
export const _유저_필터적용하기 = action("_유저_필터적용하기");
export const _새_유저_저장하기 = action("ADD_USER")
export const _새_유저_저장_취소하기 = action("_새_유저_저장_취소하기");
export const _유저_저장하기 = action("UPDATE_USER")
export const _유저_저장_취소하기 = action("_유저_저장_취소하기");


export const _유저_삭제하기 = action("_유저_삭제하기")

