<script>
import {dispatch, on, Observable} from "1px";
import {ref, select} from "../../../1px/dataLayer";
import {createDateFormat} from "../../../1px/fp/date.js";
import {data} from "../../../1px/plugins/graphql.js";
import {store} from "../../../store/store.js";
import {_QUERY_NOTICE_BY_ID_EDIT, _SAVE_NOTICE} from "../../../store/features/notice/store.notice.js";
import Select from "../../../components/atoms/Select.svelte";
import {_BACK, onRoute} from "../../../1px/plugins/router.js";
import {_공지사항_저장하기, _공지사항_편집_취소하기} from "../../../store/actions.js";

import PEI_LayoutMain from "../../../components/organisms/PEI_LayoutMain.svelte";
import PEI_ButtonFill from "../../../components/atoms/PEI_Button/PEI_ButtonFill.svelte";
import PEI_TitleBar from "../../../components/atoms/PEI_Bar/PEI_TitleBar.svelte";
import PEI_ListRowField from "../../../components/molecules/PEI_List/PEI_ListRowField.svelte";
import Icon__save from "../../../components/atoms/icons/Icon__save.svelte";
import Icon__cancel from "../../../components/atoms/icons/Icon__cancel.svelte";
import PEI_ListRowFieldTextarea from "../../../components/molecules/PEI_List/PEI_ListRowFieldTextarea.svelte";
import Icon__notice from "../../../components/atoms/icons/Icon__notice.svelte";
import {location$} from "../../../1px/plugins/router.js";
import UIPage from "../../../components/ui/UIPage.svelte";
import {_QUERY_NOTICE_BY_ID} from "../../../store/features/notice/store.notice.js";

const dateFormat = createDateFormat("yyyy년 m월 dd일 hh:ii")

// (공지사항 저장버튼 클릭) => #공지사항_저장하기(notice$)
const 공지사항_저장버튼_클릭 = (notice) => dispatch(_공지사항_저장하기(notice));

// (취소버튼 클릭) => #공지사항_편집_취소하기()
const 취소버튼_클릭 = () => dispatch(_공지사항_편집_취소하기());

// @TODO: (파일 첨부) => #파일_첨부하기
// @TODO: (파일 삭제) => #첨부파일_삭제하기
on(_공지사항_저장하기)
	.dispatch(notice => _SAVE_NOTICE(notice))

on(_SAVE_NOTICE.SUCCESS)
	.tap(() => alert('저장하였습니다.'))
	.dispatch(_BACK());

on(_SAVE_NOTICE.FAILURE)
	.tap(error => alert(error.message))
	.createEffect();

on(_공지사항_편집_취소하기)
	.filter(() => confirm("취소하시겠습니까?"))
	.dispatch(_BACK());

const _account = store.account$;


export let id;
let isAddMode = !id;
let PROJECTS = store.PROJECTS_NOTICE;

/// @TODO: 빈 오브젝트 채워주는 기능 구현할것!!!
let notice = {
	project: {id: null, name: ""},
	writer: {name: "", job: "", team: {name: ""}}
};


$: dispatch(_QUERY_NOTICE_BY_ID(id))
$: dispatch(_QUERY_NOTICE_BY_ID_EDIT())
$: PROJECTS = select($data, _QUERY_NOTICE_BY_ID_EDIT) || PROJECTS;
$: notice = select($data, _QUERY_NOTICE_BY_ID, id) || notice;
</script>


<UIPage {...$$props}>
	<PEI_LayoutMain>
		<PEI_TitleBar type="notice" text="notice">
			<Icon__notice/>
		</PEI_TitleBar>

		<section class="pei-list">
			<div class="list__item">
				<PEI_ListRowField field="프로젝트 *" status="editable" value theme>
					<Select bind:value={notice.project.id} options={PROJECTS} title="- 프로젝트 선택 -"/>
				</PEI_ListRowField>

				<PEI_ListRowField field="제목 *" status="editable" value theme>
					<input type="text" bind:value={notice.title}/>
				</PEI_ListRowField>

                {#if notice.writer.team.name || notice.writer.job }
					<PEI_ListRowField field="부서/직책" status="readonly" theme>
						<span>{ notice.writer.team.name } / { notice.writer.job }</span>
					</PEI_ListRowField>
                {/if}

                {#if notice.writer.name || notice.writer.email }
					<PEI_ListRowField field="작성자" status="readonly" theme>
						<span>{ notice.writer.name } / { notice.writer.email }</span>
					</PEI_ListRowField>
                {/if}

                {#if !isAddMode}
					<PEI_ListRowField field="등록일시" status="readonly" theme>{dateFormat(notice.created_at)}</PEI_ListRowField>
                {/if}

				<PEI_ListRowFieldTextarea field="내용" status="editable">
					<div class="textarea-box">
						<textarea class="textarea" bind:value={notice.desc}></textarea>
					</div>
				</PEI_ListRowFieldTextarea>
			</div>

			<div class="list__item" inset>
				<PEI_ButtonFill type="save" text="save" on:click={() => 공지사항_저장버튼_클릭(notice)}>
					<Icon__save/>
				</PEI_ButtonFill>
				<PEI_ButtonFill theme="mute" text="back" on:click={취소버튼_클릭}>
					<Icon__cancel/>
				</PEI_ButtonFill>
			</div>
		</section>
	</PEI_LayoutMain>
</UIPage>
