<script>
export let type;
export let text;
export let color;
</script>


<pei-button {type} {color} on:click>
	<space flex/>

	<section hbox class="btn-{type}">
		<!--		-->
		<icon class="button__icon">
			<slot/>
		</icon>
		<h1 class="button__title">{text}</h1>
		<icon class="button__icon--right">
			<slot/>
		</icon>
	</section>

	<space flex/>
</pei-button>


<style type="text/stylus">
pei-button
	display block
	position relative
	height 3rem
	margin 0.8rem 1.2rem
	border-radius .5rem
	font-size 1.2rem
	font-weight bold
	text-align center
	text-transform uppercase
	cursor pointer
	hbox()

	.button__icon
	.button__title
	.button__icon--right
		display block
		margin 0 0.2rem
		pack()

	.button__icon--right
		display none

	&:after
		content ''
		display block
		border-radius .5rem
		layer()
		position absolute
		background rgba(0, 0, 0, 0.1)
		display none

	&:hover
	&:active
		&:after
			display block

pei-button[type='add']
	color var(--button--add__color)
	border 1px solid var(--button--add__color)
	margin 0.2rem 1.2rem 0.3rem

pei-button[type='more']
	color var(--button--more__color)
	background var(--button--more__background)

	.button__icon
		display none

	.button__icon--right
		display block

pei-button[type='save']
	color var(--button--save__color)
	background var(--button--save__background)

pei-button[color='muted']
	color var(--button--muted__color)
	background var(--button--muted__background)

</style>