<script>
import {dispatch, on} from "1px";
import Validator from "../../utils/Validator.svelte";
import {_FIND_PASSWORD} from "../../../store/actions.js";
import UIActivity from "../../ui/UIPage.svelte";

import PEI_LayoutPopup from "../../organisms/PEI_LayoutPopup.svelte";
import PEI_ButtonFill from "../../atoms/PEI_Button/PEI_ButtonFill.svelte";
import PEI_ButtonInline from "../../atoms/PEI_Button/PEI_ButtonInline.svelte";
import Icon__account from "../../atoms/icons/Icon__account.svelte";
import PEI_InputboxText from "../../atoms/PEI_InputboxText.svelte";
import {_GO} from "../../../1px/plugins/router";
import {_SIGN_IN} from "../../../store/features/account/store.account.js";
import PEI_ListRowField from "../../molecules/PEI_List/PEI_ListRowField.svelte";

let id = "Sign In";

let email = "";
let password = "";

const SignIn = (validate) => dispatch(validate(_SIGN_IN(email, password)));
const FindPassword = () => dispatch(_FIND_PASSWORD());

</script>

<PEI_LayoutPopup state="no-account">
  <div inset>
    <space size="0.8"></space>

    <!--		<PEI_ListRowField field="EMAIL">-->
    <!--			<select bind:value={email}>-->
    <!--				<option>&#45;&#45; 테스트 계정 &#45;&#45;</option>-->
    <!--				<option value="ceo">CEO</option>-->
    <!--				<option value="admin[desktop]@ep.kr">admin[desktop]@ep.kr</option>-->
    <!--				<option value="pdadmin@pp.kr">pdadmin@pp.kr</option>-->
    <!--				<option value="sunny@1px.kr">sunny@1px.kr</option>-->
    <!--				<option value="1px">1px</option>-->
    <!--				<option value="pd@pp.kr">pd@pp.kr</option>-->
    <!--				<option value="admin[desktop]@pp.kr">admin[desktop]@pp.kr</option>-->
    <!--			</select>-->
    <!--		</PEI_ListRowField>-->

    <Validator let:validate={validate}>
      <form on:submit|preventDefault={() => SignIn(validate)}>
        <PEI_InputboxText placeholder="Email" input_type="text" bind:value={email} status error={validate(!email, '아이디를 입력해주세요.')}/>

        <PEI_InputboxText placeholder="Password" input_type="password" bind:value={password} status error={validate(!password, '비밀번호를 입력해주세요.')}/>

        <space size="0.8"></space>

        <PEI_ButtonFill type="signin" text="sign in" on:click={() => SignIn(validate)}>
          <Icon__account color="white"/>
        </PEI_ButtonFill>

        <button type="submit" style="visibility:hidden"/>
      </form>

      <space size="0.8"></space>
      <space size="0.8"></space>

      <!--		<PEI_ButtonInline on:click={FindPassword}>-->
      <!--			<div>forgotten password?</div>-->
      <!--		</PEI_ButtonInline>-->
    </Validator>
  </div>

</PEI_LayoutPopup>
