<script>
export let type;
export let color;
</script>

<svg class="icon__sch" viewBox="0 0 20 22" {type} {color}>
	<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
		<g id="source" transform="translate(-202.000000, -25.000000)">
			<g id="icon-schedule" transform="translate(200.000000, 24.000000)">
				<polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
				<path d="M19,3 L19,2 C19,1.45 18.55,1 18,1 C17.45,1 17,1.45 17,2 L17,3 L7,3 L7,2 C7,1.45 6.55,1 6,1 C5.45,1 5,1.45 5,2 L5,3 L4,3 C2.9,3 2,3.9 2,5 L2,21 C2,22.1 2.9,23 4,23 L20,23 C21.1,23 22,22.1 22,21 L22,5 C22,3.9 20.1,3 19,3 Z M19,21 L5,21 C4.45,21 4,20.55 4,20 L4,8 L20,8 L20,20 C20,20.55 19.55,21 19,21 Z"
					  id="Shape" fill="#ffffff" fill-rule="nonzero"></path>
			</g>
		</g>
	</g>
</svg>

<style type="text/stylus">
svg.icon__sch
	width 2 * 0.7rem
	height 2.2 * 0.7rem

	&[type='big']
		width 2rem
		height 2.2rem

	&[color='black'] path
		fill $black
</style>