<script>
export let field;
export let status;
export let checked;
</script>


<div class="list__row" {status}>
  <div class="row__field">{field}</div>
  <label class="row__value" hbox>
    <div class="value__checkbox">
      <input type="checkbox" name="" bind:checked/>
    </div>
    <div flex>
      <slot/>
    </div>
  </label>
</div>


<style type="text/stylus">
.list__row
  min-height 3.6rem
  font-size 1.4rem
  border-bottom 1px dashed $light-gray
  hbox()

  .row__field
    width 9rem
    font-weight bold
    margin-left 1.2rem

  .row__value
    padding-right 1.2rem
    flex()

  .value__checkbox
    margin-right .7rem

  &[status='editable']
    .value__text
      display none

  &[status='readonly']
    .value__inputbox
      display none


</style>