<script>
import {dispatch, on, select} from "1px";
import {data} from '../../1px/plugins/graphql.js';
import {_GO} from '../../1px/plugins/router.js';
import Icon__add from "../../components/atoms/icons/Icon__add.svelte";
import Icon__filter from "../../components/atoms/icons/Icon__filter.svelte";
import Icon__project from "../../components/atoms/icons/Icon__project.svelte";
import PEI_SearchBar from "../../components/atoms/PEI_Bar/PEI_SearchBar.svelte";
import PEI_TitleBar from "../../components/atoms/PEI_Bar/PEI_TitleBar.svelte";
import PEI_ButtonFill from "../../components/atoms/PEI_Button/PEI_ButtonFill.svelte";
import PEI_Tab from "../../components/atoms/PEI_Button/PEI_Tab.svelte";
import PEI_Inputbox from "../../components/atoms/PEI_Inputbox.svelte";
import PEI_InputSelect from "../../components/atoms/PEI_InputSelect.svelte";
import PEI_ProjectListRow from "../../components/molecules/PEI_List/PEI_ProjectListRow.svelte";
import PEI_LayoutMain from "../../components/organisms/PEI_LayoutMain.svelte";

import UIPage from "../../components/ui/UIPage.svelte";
import {_새_프로젝트_추가하기, _프로젝트_상세페이지로_이동} from "../../store/actions.js";
import {account$} from "../../store/features/account/store.account.js";
import {_QUERY_PROJECTS} from "../../store/features/project/store.project.js";
import {store} from "../../store/store.js";

const ProjectDetail = (project) => dispatch(_프로젝트_상세페이지로_이동(project));
const 프로젝트_추가버튼_클릭 = () => dispatch(_새_프로젝트_추가하기());

on(_새_프로젝트_추가하기).dispatch(_GO("/projects/add"))
on(_프로젝트_상세페이지로_이동).dispatch(project => _GO("/projects/:id", project.id))

const account = store.account$

///
let YEARS = Array(new Date().getFullYear() - 2017 + 2).fill(new Date().getFullYear() + 1).map((year, index) => ({id: year - index, name: (year - index) + "년"}))
let TEAMS = [];

let search = "";
let year = null
let status = $account$.job === "대표이사" ? "전체" : "진행중";
let projects = [];
let team_id = $account.team.id

$: dispatch(_QUERY_PROJECTS(search, year, status === "전체" ? "" : status, team_id))
$: TEAMS = select($data, _QUERY_PROJECTS, "teams") || TEAMS;
$: projects = select($data, _QUERY_PROJECTS, "projects") || projects
</script>


<UIPage {...$$props}>
  <PEI_LayoutMain>
    <PEI_TitleBar type="filter" text="filter">
      <Icon__filter/>
    </PEI_TitleBar>

    <section inset>
      <PEI_Inputbox>
        <PEI_SearchBar/>
      </PEI_Inputbox>

      <PEI_Inputbox>
        <PEI_InputSelect type="emphasised" name="YEAR" options={YEARS} bind:value={year}/>
      </PEI_Inputbox>

      <PEI_Inputbox>
        <PEI_InputSelect type="emphasised" name="TEAM" options={TEAMS} bind:value={team_id}/>
      </PEI_Inputbox>

      <PEI_Tab tabs={["진행중", "완료", "전체"]} bind:selectedTab={status}/>
    </section>

    <section class="pei-list">
      <PEI_TitleBar type="project" text="project">
        <Icon__project/>
      </PEI_TitleBar>

      <div class="list__item">
        {#each projects as row}
          <PEI_ProjectListRow project={row} on:click={() => ProjectDetail(row)}/>
        {/each}
      </div>
    </section>


    <section inset>
      <PEI_ButtonFill text="new project" theme on:click={프로젝트_추가버튼_클릭}>
        <Icon__add color="white" type/>
      </PEI_ButtonFill>
    </section>

  </PEI_LayoutMain>
</UIPage>
