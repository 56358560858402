<script>
import {dispatch, on} from "1px";
import {ref, select} from "../../1px/dataLayer";
import {Observable} from "../../1px/observable";
import {data} from "../../1px/plugins/graphql.js";
import {_GO, onRoute} from "../../1px/plugins/router.js";
import {_QUERY_USERS} from "../../store/features/account/store.account.js";

import {store} from "../../store/store.js";
import {_유저_검색하기, _유저_상세페이지로_이동, _유저_필터적용하기, _새_유저_저장하기} from "../../store/actions.js";

import UIActivity from "../../components/ui/UIPage.svelte";
import PEI_ButtonFill from "../../components/atoms/PEI_Button/PEI_ButtonFill.svelte";
import PEI_TitleBar from "../../components/atoms/PEI_Bar/PEI_TitleBar.svelte";
import PEI_SearchBar from "../../components/atoms/PEI_Bar/PEI_SearchBar.svelte";
import PEI_ListRowBoard from "../../components/molecules/PEI_List/PEI_ListRowBoard.svelte";
import PEI_LayoutMain from "../../components/organisms/PEI_LayoutMain.svelte";
import Icon__notice from "../../components/atoms/icons/Icon__notice.svelte";
import PEI_Inputbox from "../../components/atoms/PEI_Inputbox.svelte";
import PEI_InputSelect from "../../components/atoms/PEI_InputSelect.svelte";
import Icon__filter from "../../components/atoms/icons/Icon__filter.svelte";
import PEI_NoticeListRow from "../../components/molecules/PEI_List/PEI_NoticeListRow.svelte";
import UIPage from "../../components/ui/UIPage.svelte";
import Icon__add from "../../components/atoms/icons/Icon__add.svelte";
import Icon__account from "../../components/atoms/icons/Icon__account.svelte";
import PEI_UserListRow from "../../components/molecules/PEI_List/PEI_UserListRow.svelte";

const 유저_클릭 = (user) => dispatch(_유저_상세페이지로_이동(user));
const 유저_추가버튼_클릭 = () => dispatch(_새_유저_저장하기);


/// Effector
on(_새_유저_저장하기)
  .dispatch(_GO("/admin/add"))

on(_유저_상세페이지로_이동)
  .dispatch(user => _GO("/admin/:id", user.id))

on(_유저_필터적용하기)
  .todo("유저 목록에서 검색어 정보와 일치하는 정보 찾기 => #유저_검색하기(search$)")
  .createEffect();

on(_유저_검색하기)
  .todo("_유저_검색하기")
  .createEffect();


const filter_search = ref("");
const filter_project = ref("");
// Observable.combineLatest(filter_search, filter_project).dispatch(_유저_필터적용하기)


/// Selector
let users = [];

let COMPANIES = [];
let TEAMS = [];
let JOB = [];

let companyId;
let team_id;
let job;

data
$: dispatch(_QUERY_USERS)
$: users = select($data, _QUERY_USERS) || users;

</script>


<UIPage {...$$props}>
  <PEI_LayoutMain>
    <section class="pei-list">
      <PEI_TitleBar type="user" text="staff">
        <Icon__account color="white"/>
      </PEI_TitleBar>

      <div inset>
        <PEI_ButtonFill text="add staff" on:click={유저_추가버튼_클릭}>
          <Icon__add color="white" type/>
        </PEI_ButtonFill>
      </div>

      <div class="list__item">
        {#each users as row}
          <PEI_UserListRow user={row} on:click={() => 유저_클릭(row)}/>
        {/each}
      </div>

    </section>

  </PEI_LayoutMain>
</UIPage>

