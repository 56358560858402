<script>
export let tabs = ["전체", "진행중", "완료"];
export let selectedTab = tabs[0];
</script>


<section class="pei-tabs">
    {#each tabs as tab}
		<div class="tabs__button" selected={tab === selectedTab} on:click={() => selectedTab = tab}>
			<div>{tab}</div>
		</div>
    {/each}
</section>


<style type="text/stylus">
.pei-tabs
	height 3.6rem
	font-size 1.4rem
	text-align center
	margin-top .8rem
	hbox()

.tabs__button
	flex()
	position relative
	height 100%
	border 1px solid #999
	overfow hidden
	cursor pointer
	hbox()

	> *
		flex()

	&:after
		content ''
		display block
		layer()
		position absolute
		background rgba(0,0,0,0.1)
		display none

	&:first-child
	&:first-child:after
		border-right 0
		border-top-left-radius 1.8rem
		border-bottom-left-radius 1.8rem

	&:last-child
	&:last-child:after
		border-left 0
		border-top-right-radius 1.8rem
		border-bottom-right-radius 1.8rem

	&:hover
	&:active
		&:after
			display block

	&[selected="true"]
		color $black
		background #ddd
		font-weight bold
		cursor pointer
</style>