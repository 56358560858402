<script>
export let title = "- [선택] -";
export let options = [];
export let value;
</script>

<select {...$$props} bind:value>
	<option value={null}>{title}</option>
	{#each options as option}
		<option value={option.id || option.name}>{option.name}</option>
	{/each}
</select>