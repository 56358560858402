<script>
import {dispatch, on} from "1px";
import {__dateOffset, __today} from "../1px/fp/date.js";
import PEI_Schedule__Group from "../components/organisms/schedules/PEI_Schedule__Group.svelte";
import {data} from "../1px/plugins/graphql.js";
import {
	_MORE_SCHEDULE,
	_MORE_NOTICE,
	_MORE_PROJECTS,
	_새_프로젝트_추가하기,
	_프로젝트_상세페이지로_이동,
	_새_스케줄_추가하기,
	_스케줄_상세보기,
	_공지사항_상세페이지로_이동,
} from "../store/actions.js";
import {_FETCH} from "../1px/plugins/collection.js";
import {store} from "../store/store.js";
import {_BACK, _GO, _ROUTE, onRoute} from "../1px/plugins/router.js";

import PEI_LayoutMain from "../components/organisms/PEI_LayoutMain.svelte";
import PEI_Button from "../components/atoms/PEI_Button/PEI_Button.svelte";
import PEI_TitleBar from "../components/atoms/PEI_Bar/PEI_TitleBar.svelte";
import PEI_SectionBar from "../components/atoms/PEI_Bar/PEI_SectionBar.svelte";
import PEI_ListRowBoard from "../components/molecules/PEI_List/PEI_ListRowBoard.svelte";
import PEI_ListRowSingle from "../components/molecules/PEI_List/PEI_ListRowSingle.svelte";
import PEI_ListRowTimeline from "../components/molecules/PEI_List/PEI_ListRowTimeline.svelte";
import Icon__add from "../components/atoms/icons/Icon__add.svelte";
import Icon__more from "../components/atoms/icons/Icon__more.svelte";
import UIActivity from "../components/ui/UIPage.svelte";
import Icon__schedule from "../components/atoms/icons/Icon__schedule.svelte";
import Icon__notice from "../components/atoms/icons/Icon__notice.svelte";
import Tag from "../components/atoms/icons/Tag.svelte";
import Icon__project from "../components/atoms/icons/Icon__project.svelte";
import Icon__element from "../components/atoms/icons/Icon__element.svelte";
import Icon__planit from "../components/atoms/icons/Icon__planit.svelte";
import PEI_NoticeListRow from "../components/molecules/PEI_List/PEI_NoticeListRow.svelte";
import PEI_ScheduleListRow from "../components/molecules/PEI_List/PEI_ScheduleListRow.svelte";
import PEI_ProjectListRow from "../components/molecules/PEI_List/PEI_ProjectListRow.svelte";
import UIPage from "../components/ui/UIPage.svelte";
import {select} from "../1px/dataLayer";
import {_QUERY_MAIN} from "../store/features/project/store.project.js";

const MoreSchedule = () => dispatch(_MORE_SCHEDULE());
const MoreNotice = () => dispatch(_MORE_NOTICE());
const MoreProjects = () => dispatch(_MORE_PROJECTS());

const ScheduleDetail = (data) => dispatch(_스케줄_상세보기(data));
const NoticeDetail = (data) => dispatch(_공지사항_상세페이지로_이동(data));

const AddProject = () => dispatch(_새_프로젝트_추가하기());
const ProjectDetail = (data) => dispatch(_프로젝트_상세페이지로_이동(data));

on(_MORE_NOTICE).dispatch(() => _GO("/notices"))
on(_MORE_SCHEDULE).dispatch(() => _GO("/schedules"))
on(_MORE_PROJECTS).dispatch(() => _GO("/projects"))

on(_새_스케줄_추가하기).dispatch(start_datetime => _GO("/schedules/add/:start_datetime", start_datetime))
on(_스케줄_상세보기).dispatch(schedule => _GO("/schedules/:id", schedule.id))
on(_공지사항_상세페이지로_이동).dispatch(notice => _GO("/notices/:id", notice.id))
on(_프로젝트_상세페이지로_이동).dispatch(project => _GO("/projects/:id", project.id))


///
const calendar = store.calendar;

let notices = [];
let projects = [];
let schedules = [];

$: dispatch(_QUERY_MAIN())
$: schedules = select($data, _QUERY_MAIN, "schedules_by_account") || schedules;
$: notices = select($data, _QUERY_MAIN, "notices") || notices;
$: projects = select($data, _QUERY_MAIN, "projects_by_account") || projects;

let today = __today();
let nextDay = __dateOffset(today, "day", 7);
</script>


<UIPage {...$$props}>
	<PEI_LayoutMain>

		<PEI_TitleBar type="schedule" text="schedule">
			<Icon__schedule/>
		</PEI_TitleBar>

		<section class="pei-list">
			<!-- @NOTE: 오늘 날짜에 가장 가까운 순으로 7일 간의 데이터 노출 -->
			<PEI_Schedule__Group start={today} end={nextDay} {schedules}/>

			<PEI_Button type="more" text="more schedule" color on:click={MoreSchedule}>
				<Icon__more color/>
			</PEI_Button>
		</section>


		<section class="pei-list">
			<PEI_TitleBar type="notice" text="notice">
				<Icon__notice/>
			</PEI_TitleBar>

			{#each notices as notice}
				<PEI_NoticeListRow {notice} on:click={() => NoticeDetail(notice)}/>
			{/each}

			<PEI_Button type="more" text="more notice" color on:click={MoreNotice}>
				<Icon__more color/>
			</PEI_Button>
		</section>


		<section class="pei-list">
			<PEI_TitleBar type="project" text="project">
				<Icon__project/>
			</PEI_TitleBar>

			{#each projects as project}
				<PEI_ProjectListRow {project} on:click={() => ProjectDetail(project)}/>
			{/each}

			<PEI_Button type="more" text="more projects" color on:click={MoreProjects}>
				<Icon__more color/>
			</PEI_Button>
		</section>

	</PEI_LayoutMain>
</UIPage>
