<script>
export let theme
export let type
</script>

<pei-section-bar {theme} {type}>
	<slot/>
	{#if type == 'calendar'}
		<div class="button__inline" on:click>
			<div>+</div>
			<space size="0.4"/>
			<div class="hidden">ADD SCHEDULE</div>
		</div>
	{/if}
</pei-section-bar>


<style type="text/stylus">
pei-section-bar
	hbox()
	height 1.8rem
	padding 0 1.2rem
	font-size 1.2rem
	font-weight bold
	text-align center
	position sticky
	top 1.9rem
	z-index 9998
	color var(--section-bar__color)
	background var(--section-bar__background)

	&[type='calendar']
		height 2rem
		font-size 1.4rem

	&[theme='primary']
		background var(--primary)

	&[theme='secondary']
		background var(--secondary)

.button__inline
	hbox()
	position absolute
	left 11.1rem
	font-size 1.1rem
	font-weight regular
	cursor pointer

	.hidden
		visibility hidden

	&:hover
		.hidden
			visibility visible
</style>