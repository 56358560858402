import {action_GraphQL} from "../../../1px/plugins/graphql.js";

/// GQL
import GQL from "./store.notice.graphql";

/// Event
export const _QUERY_NOTICES = action_GraphQL(GQL, "_QUERY_NOTICES");
export const _QUERY_NOTICE_BY_ID = action_GraphQL(GQL, "_QUERY_NOTICE_BY_ID");
export const _QUERY_NOTICE_BY_ID_EDIT = action_GraphQL(GQL, "_QUERY_NOTICE_BY_ID_EDIT");
export const _SAVE_NOTICE = action_GraphQL(GQL, "_SAVE_NOTICE");
export const _DELETE_NOTICE = action_GraphQL(GQL, "_DELETE_NOTICE");