<script>
import {dispatch, on} from "1px";
import {ref, select} from "../../1px/dataLayer";
import {Observable} from "../../1px/observable";
import {data} from "../../1px/plugins/graphql.js";
import {_GO, onRoute} from "../../1px/plugins/router.js";
import {_QUERY_NOTICES} from "../../store/features/notice/store.notice.js";
import {PROJECTS} from "../../store/store.enum.js";
import {store} from "../../store/store.js";
import {_공지사항_검색하기, _공지사항_상세페이지로_이동, _공지사항_필터적용하기, _새_공지사항_추가하기} from "../../store/actions.js";

import UIActivity from "../../components/ui/UIPage.svelte";
import PEI_ButtonFill from "../../components/atoms/PEI_Button/PEI_ButtonFill.svelte";
import PEI_TitleBar from "../../components/atoms/PEI_Bar/PEI_TitleBar.svelte";
import PEI_SearchBar from "../../components/atoms/PEI_Bar/PEI_SearchBar.svelte";
import PEI_ListRowBoard from "../../components/molecules/PEI_List/PEI_ListRowBoard.svelte";
import PEI_LayoutMain from "../../components/organisms/PEI_LayoutMain.svelte";
import Icon__notice from "../../components/atoms/icons/Icon__notice.svelte";
import PEI_Inputbox from "../../components/atoms/PEI_Inputbox.svelte";
import PEI_InputSelect from "../../components/atoms/PEI_InputSelect.svelte";
import Icon__filter from "../../components/atoms/icons/Icon__filter.svelte";
import PEI_NoticeListRow from "../../components/molecules/PEI_List/PEI_NoticeListRow.svelte";
import UIPage from "../../components/ui/UIPage.svelte";
import Icon__add from "../../components/atoms/icons/Icon__add.svelte";

const 공지사항_클릭 = (notice) => dispatch(_공지사항_상세페이지로_이동(notice));
const 공지사항_추가버튼_클릭 = () => dispatch(_새_공지사항_추가하기());


/// Effector
on(_공지사항_상세페이지로_이동)
  .dispatch(notice => _GO("/notices/:id", notice.id))

on(_새_공지사항_추가하기)
  .dispatch(_GO("/notices/add"))

on(_공지사항_필터적용하기)
  .todo("공지사항 목록에서 검색어 정보와 일치하는 정보 찾기 => #공지사항_검색하기(search$)")
  .createEffect();

on(_공지사항_검색하기)
  .todo("_공지사항_검색하기")
  .createEffect();


const filter_search = ref("");
const filter_project = ref("");
// Observable.combineLatest(filter_search, filter_project).dispatch(_공지사항_필터적용하기)


/// Selector
let projectId = null;

let projects = [];
let notices = [];

data
$: dispatch(_QUERY_NOTICES(projectId))
$: projects = select($data, _QUERY_NOTICES, "projects") || projects;
$: notices = select($data, _QUERY_NOTICES, "notices") || notices;
</script>


<UIPage {...$$props}>
  <PEI_LayoutMain>
    <PEI_TitleBar type="filter" text="filter">
      <Icon__filter/>
    </PEI_TitleBar>

    <section inset>
      <PEI_Inputbox>
        <PEI_SearchBar bind:value={$filter_search}/>
      </PEI_Inputbox>

      <PEI_Inputbox>
        <PEI_InputSelect bind:value={projectId} options={projects} name="전체" type="emphasised">
          <option value="골지사항">공지사항</option>
        </PEI_InputSelect>
      </PEI_Inputbox>
    </section>

    <section class="pei-list">
      <PEI_TitleBar type="notice" text="notice">
        <Icon__notice/>
      </PEI_TitleBar>

      <div class="list__item">
        {#each notices as row}
          <PEI_NoticeListRow notice={row} on:click={() => 공지사항_클릭(row)}/>
        {/each}
      </div>
    </section>

    <div inset>
      <PEI_ButtonFill text="add notice" on:click={공지사항_추가버튼_클릭}>
        <Icon__add color="white" type/>
      </PEI_ButtonFill>
    </div>

  </PEI_LayoutMain>
</UIPage>
