<script>
export let type = null;
export let color = null;
</script>

<svg class="icon__notice" viewBox="0 0 16 20" {type} {color}>
	<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
		<g id="source" transform="translate(-204.000000, -50.000000)">
			<g id="icon-notice" transform="translate(200.000000, 48.000000)">
				<polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
				<path d="M12,22 C13.1,22 14,21.1 14,20 L10,20 C10,21.1 10.89,22 12,22 Z M18,16 L18,11 C18,7.93 16.36,5.36 13.5,4.68 L13.5,4 C13.5,3.17 12.83,2.5 12,2.5 C11.17,2.5 10.5,3.17 10.5,4 L10.5,4.68 C7.63,5.36 6,7.92 6,11 L6,16 L4.71,17.29 C4.08,17.92 4.52,19 5.41,19 L18.58,19 C19.47,19 19.92,17.92 19.29,17.29 L18,16 Z"
					id="Shape" fill="#ffffff" fill-rule="nonzero"></path>
			</g>
		</g>
	</g>
</svg>

<style type="text/stylus">
svg.icon__notice
	width 1.6 * 0.7rem
	height 2 * 0.7rem

	&[type='big']
		width 1.6 * 0.9rem
		height 2 * 0.9rem

	&[color='black'] path
		fill $black
</style>