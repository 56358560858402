<script>
import PEI_Header from "../molecules/PEI_Header.svelte";
import PEI_Footer from "../atoms/PEI_Footer.svelte";
import {store} from "../../store/store";

let account = store.account$;
</script>

<PEI_Header>
    {#if !$account}
		<div><strong color="highlight-secondary">Administration</strong></div>
    {:else}
		<div>{$account.team} <span color="highlight-secondary"><strong>{$account.name}</strong> {$account.job}</span>
		</div>
    {/if}
</PEI_Header>

<section vbox flex>
	<slot/>
	<PEI_Footer/>
</section>