<script>
import {ref} from "../../1px/dataLayer";

const state$ = ref("init")

let errors = Object.create(null)

const validate = (cond, message) => {
  if (Array.isArray(cond)) return run(cond)
  errors[message] = typeof cond === "function" ? cond : () => !!cond;
  return state$.map(state => state !== "init" && cond)
}

const run = (action) => {
  state$.next("touched")
  const error = Object.keys(errors).find(error => errors[error]())
  if (error) {
    return [action[0] + ".FAILURE", new Error(error)]
  }

  return action
}
</script>

<slot {validate}/>