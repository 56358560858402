import {PROJECTS, PROJECTS_NOTICE} from "./store.enum.js";
import {account$} from "./features/account/store.account.js";

let calendar = [
  {month: 3, day: 24, dayofweek: '수'},
  {month: 3, day: 25, dayofweek: '목'},
  {month: 3, day: 26, dayofweek: '금'},
  {month: 3, day: 27, dayofweek: '토', theme: 'primary'},
  {month: 3, day: 28, dayofweek: '일', theme: 'secondary'},
  {month: 3, day: 29, dayofweek: '월'}
];


let staff = ['임민철', '백종열', '강수려'];
let years = [2020, 2019, 2018, 2017];
let months = ['01', '02', '03', '04'];

let companies = [
  {name: 'Element Pictures'},
  {name: 'Planit Production'}
];

let planit = {}
planit.jobs = [
  {name: '대표이사'},
  {name: '이사'},
  {name: '부사장'},
  {name: '실장'},
  {name: '팀장'},
  {name: '부장'},
  {name: '차장'},
  {name: '대리'},
  {name: '사원'},
  {name: 'EP'},
  {name: 'J.EP'},
  {name: 'CP'},
  {name: 'PD'}
];


//대표, 기획실, 제작관리부, 제작1팀 ~ 제작 10팀, 글로벌팀
planit.teams = [
  {
    "id": "7nLlrNz0cchBrX5LHXgy",
    "name": "대표",
    "__typename": "Team"
  },
  {
    "id": "WvXWmXVFxIMVcOJAq7nm",
    "name": "기획실",
    "__typename": "Team"
  },
  {
    "id": "66AlL5d1aoxQmWuwIC3O",
    "name": "제작관리부",
    "__typename": "Team"
  },
  {
    "id": "zmkz2flf2xiFuX8VglZM",
    "name": "제작1팀",
    "__typename": "Team"
  },
  {
    "id": "0gR9HtDZx5TGkldDdUXY",
    "name": "제작2팀",
    "__typename": "Team"
  },
  {
    "id": "NlWn9j6eiriLK4EK0sAv",
    "name": "제작3팀",
    "__typename": "Team"
  },
  {
    "id": "Do5CC8NVZxaGmUzstM3C",
    "name": "제작4팀",
    "__typename": "Team"
  },
  {
    "id": "zpMbM1D47o59TDW4fRuR",
    "name": "제작5팀",
    "__typename": "Team"
  },
  {
    "id": "9fuWIaGZSpG8WPLU6cLN",
    "name": "제작6팀",
    "__typename": "Team"
  },
  {
    "id": "hl9RzZo3wahd5H0qNF89",
    "name": "제작7팀",
    "__typename": "Team"
  },
  {
    "id": "Zoh3sFM7BCnxrJGrVfig",
    "name": "제작8팀Zo",
    "__typename": "Team"
  },
  {
    "id": "GNv2S8CgkPaYinEtQPC1",
    "name": "제작9팀GN",
    "__typename": "Team"
  },
  {
    "id": "CWgpTj9erhNqwYHUI366",
    "name": "제작10팀",
    "__typename": "Team"
  },
  {
    "id": "NuYcLvNQpCl2Ii6ygjHn",
    "name": "제작11팀",
    "__typename": "Team"
  },
  {
    "id": "ax04xkP1sdd9lOIirkjI",
    "name": "제작12팀",
    "__typename": "Team"
  },
  {
    "id": "Etz9iPKkn0fYGIs0jJdP",
    "name": "글로벌팀",
    "__typename": "Team"
  },
  {
    "id": "Zoh3sFM7BCnxrJGrVfig",
    "name": "test팀",
    "__typename": "Team"
  }
];
let element = {}
element.jobs = [
  {name: '대표이사'},
  {name: '이사'},
  {name: '부장'},
  {name: '차장'},
  {name: '대리'},
  {name: '사원'},
  {name: 'EP'},
  {name: 'J.EP'},
  {name: 'CP'},
  {name: 'PD'},
  {name: '감독'},
  {name: '조감독'},
  {name: '비주얼리서쳐'}
];
element.teams = [
  {
    "id": "qqyP67eUv4xyFNLzNI6I",
    "name": "대표",
    "__typename": "Team"
  },
  {
    "id": "DrdbVIwx0eluf1w49vew",
    "name": "제작관리부",
    "__typename": "Team"
  },
  {
    "id": "8IzYLmpf4wx2PNllLLk9",
    "name": "PD팀",
    "__typename": "Team"
  },
  {
    "id": "ffkyy7D0BCalcbWZJJz5",
    "name": "연출팀",
    "__typename": "Team"
  }
];


// let selectedSchedule = schedule1[0];
// let schedule1$ = ref(schedule1, "schedule1$");
// let schedule2$ = ref(schedule2, "schedule2$");
// let selectedSchedule$ = ref(selectedSchedule);

export const store = {
  PROJECTS,
  PROJECTS_NOTICE,
  companies,
  staff,
  years,
  months,
  calendar,
  planit,
  element,

  account$
}