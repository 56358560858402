<script>
import {dispatch, on} from "1px";
import {_프로젝트_편집하기, _공지사항_상세페이지로_이동, _프로젝트_삭제하기} from "../../../store/actions.js";
import {_BACK, _GO} from "../../../1px/plugins/router.js";
import {_QUERY_NOTICES} from "../../../store/features/notice/store.notice.js";
import {_QUERY_PROJECT_BY_ID, _DELETE_PROJECT} from "../../../store/features/project/store.project.js";
import {_QUERY_SCHEDULES} from "../../../store/features/schedule/store.schedule.js";

import PEI_LayoutMain from "../../../components/organisms/PEI_LayoutMain.svelte";
import PEI_ButtonFill from "../../../components/atoms/PEI_Button/PEI_ButtonFill.svelte";
import PEI_TitleBar from "../../../components/atoms/PEI_Bar/PEI_TitleBar.svelte";
import PEI_SectionBar from "../../../components/atoms/PEI_Bar/PEI_SectionBar.svelte";
import PEI_ListRowField from "../../../components/molecules/PEI_List/PEI_ListRowField.svelte";
import Icon__edit from "../../../components/atoms/icons/Icon__edit.svelte";
import Icon__delete from "../../../components/atoms/icons/Icon__delete.svelte";
import Icon__project from "../../../components/atoms/icons/Icon__project.svelte";
import {data} from "../../../1px/plugins/graphql.js";
import UIPage from "../../../components/ui/UIPage.svelte";
import {select} from "../../../1px/dataLayer";
import Icon__notice from "../../../components/atoms/icons/Icon__notice.svelte";
import PEI_NoticeListRow from "../../../components/molecules/PEI_List/PEI_NoticeListRow.svelte";
import PEI_ProjectListRow from "../../../components/molecules/PEI_List/PEI_ProjectListRow.svelte";
import PEI_Button from "../../../components/atoms/PEI_Button/PEI_Button.svelte";
import Icon__more from "../../../components/atoms/icons/Icon__more.svelte";
import Icon__schedule from "../../../components/atoms/icons/Icon__schedule.svelte";
import PEI_Schedule__Group from "../../../components/organisms/schedules/PEI_Schedule__Group.svelte";

const 프로젝트_편집버튼_클릭 = (project) => dispatch(_프로젝트_편집하기(project));
const 프로젝트_삭제버튼_클릭 = (project) => dispatch(_프로젝트_삭제하기(project));
const NoticeDetail = (data) => dispatch(_공지사항_상세페이지로_이동(data));

on(_프로젝트_편집하기)
	.dispatch(project => _GO("/projects/:id/edit", project.id))

on(_프로젝트_삭제하기)
	.filter(() => confirm("삭제하시겠습니까?"))
	.dispatch(project => _DELETE_PROJECT(project.id))

on(_DELETE_PROJECT.SUCCESS)
	.tap(() => alert('삭제되었습니다.'))
	.dispatch(_BACK())

on(_DELETE_PROJECT.FAILURE)
	.tap(error => alert(error.message))
	.dispatch(_BACK())

on(_공지사항_상세페이지로_이동)
	.dispatch(notice => _GO("/notices/:id", notice.id))


///
export let id = null;
let project = null;
let notices = [];
let schedules = [];

data
$: dispatch(_QUERY_PROJECT_BY_ID(id))
$: dispatch(_QUERY_NOTICES(id))
$: dispatch(_QUERY_SCHEDULES(id))
$: project = select($data, _QUERY_PROJECT_BY_ID, id) || project
$: notices = select($data, _QUERY_NOTICES, "notices") || notices;
$: schedules = select($data, _QUERY_SCHEDULES, "schedules") || schedules;
</script>


<UIPage {...$$props}>
	<PEI_LayoutMain>
		<PEI_TitleBar type="project" text="project">
			<Icon__project/>
		</PEI_TitleBar>

		{#if project}
			<section class="pei-list">
				<div class="list__item">
					<PEI_SectionBar theme type>
						<div flex>기본 정보</div>
					</PEI_SectionBar>

					<PEI_ListRowField field="작성자" status="readonly" theme>{project.writer.name || '-'}</PEI_ListRowField>
					<PEI_ListRowField field="제작팀" status="readonly" theme>{project.team.name || '-'}</PEI_ListRowField>
					<PEI_ListRowField field="광고주" status="readonly" theme>{project.client || '-'}</PEI_ListRowField>
					<PEI_ListRowField field="작품명" status="readonly" theme>{project.name || '-'}</PEI_ListRowField>
					<PEI_ListRowField field="색상" status="readonly" theme>{project.color || '-'}</PEI_ListRowField>
					<PEI_ListRowField field="상태" status="readonly" theme>{project.status || '-'}</PEI_ListRowField>
				</div>

				<div class="list__item">
					<PEI_SectionBar theme="secondary" type>
						<div flex>대행사 정보</div>
					</PEI_SectionBar>

					<PEI_ListRowField field="대행사" status="readonly" theme>
						{project.agency.name || '-'}
					</PEI_ListRowField>

					<PEI_ListRowField field="CD" status="readonly" theme>
						{project.agency.CD || '-'}
					</PEI_ListRowField>

					<PEI_ListRowField field="CW" status="readonly" theme>
						{project.agency.CW || '-'}
					</PEI_ListRowField>

					<PEI_ListRowField field="AE" status="readonly" theme>
						{project.agency.AE || '-'}
					</PEI_ListRowField>

					<PEI_ListRowField field="제작관리" status="readonly" theme>
						{project.agency.production_management || '-'}
					</PEI_ListRowField>
				</div>

				<div class="list__item">
					<PEI_SectionBar theme="primary" type>
						<div flex>담당자 정보</div>
					</PEI_SectionBar>

					<PEI_ListRowField field="기획실장" status="readonly" theme>
						{project.PIC.planning_director || '-'}
					</PEI_ListRowField>

					<PEI_ListRowField field="EP" status="readonly" theme>
						{project.PIC.EP || '-'}
					</PEI_ListRowField>

					<PEI_ListRowField field="PD" status="readonly" theme>
						{project.PIC.PD || '-'}
					</PEI_ListRowField>

					<PEI_ListRowField field="감독" status="readonly" theme>
						{project.PIC.director || '-'}
					</PEI_ListRowField>

					<PEI_ListRowField field="조감독" status="readonly" theme>
						{project.PIC.assistant_director || '-'}
					</PEI_ListRowField>
				</div>

				<div class="list__item">
					<PEI_SectionBar theme="primary" type>
						<div flex>스탭 정보</div>
					</PEI_SectionBar>

					<PEI_ListRowField field="촬영감독" status="readonly" theme>
						{project.staff.photography_director || '-'}
					</PEI_ListRowField>

					<PEI_ListRowField field="조명감독" status="readonly" theme>
						{project.staff.gaffer_director || '-'}
					</PEI_ListRowField>

					<PEI_ListRowField field="그립팀" status="readonly" theme>
						{project.staff.grip_team || '-'}
					</PEI_ListRowField>

					<PEI_ListRowField field="로케이션팀" status="readonly" theme>
						{project.staff.location_team || '-'}
					</PEI_ListRowField>

					<PEI_ListRowField field="아트실장" status="readonly" theme>
						{project.staff.art_director || '-'}
					</PEI_ListRowField>

					<PEI_ListRowField field="헤어실장" status="readonly" theme>
						{project.staff.hair_director || '-'}
					</PEI_ListRowField>

					<PEI_ListRowField field="메이크업실장" status="readonly" theme>
						{project.staff.makeup_director || '-'}
					</PEI_ListRowField>

					<PEI_ListRowField field="의상실장" status="readonly" theme>
						{project.staff.dress_director || '-'}
					</PEI_ListRowField>

					<PEI_ListRowField field="모델 에이전시" status="readonly" theme>
						{project.staff.model_agency || '-'}
					</PEI_ListRowField>

					<PEI_ListRowField field="모델(셀럽)" status="readonly" theme>
						{project.staff.model_celeb || '-'}
					</PEI_ListRowField>

					<PEI_ListRowField field="특수효과팀" status="readonly" theme>
						{project.staff.special_effect || '-'}
					</PEI_ListRowField>
				</div>

				<div class="list__item">
					<PEI_SectionBar theme="primary" type>
						<div flex>후반 정보</div>
					</PEI_SectionBar>

					<PEI_ListRowField field="편집" status="readonly"
						theme>{project.after.editor || '-'}</PEI_ListRowField>
					<PEI_ListRowField field="2D" status="readonly" theme>{project.after.two_d || '-'}</PEI_ListRowField>
					<PEI_ListRowField field="3D" status="readonly"
						theme>{project.after.three_d || '-'}</PEI_ListRowField>
					<PEI_ListRowField field="ART" status="readonly" theme>{project.after.art || '-'}</PEI_ListRowField>
					<PEI_ListRowField field="녹음실" status="readonly"
						theme>{project.after.recording || '-'}</PEI_ListRowField>
					<PEI_ListRowField field="오디오PD" status="readonly"
						theme>{project.after.audio_pd || '-'}</PEI_ListRowField>
					<PEI_ListRowField field="D.I" status="readonly" theme>{project.after.di || '-'}</PEI_ListRowField>
				</div>

				<div class="list__item">
					<PEI_SectionBar theme="primary" type>
						<div flex>해외촬영스탭</div>
					</PEI_SectionBar>

					<PEI_ListRowField field="해외 프로덕션" status="readonly" theme>
						{project.global.production || '-'}
					</PEI_ListRowField>

					<PEI_ListRowField field="국가" status="readonly" theme>
						{project.global.nation || '-'}
					</PEI_ListRowField>
				</div>

				{#if project.is_editable}
					<div class="list__item" inset>
						<PEI_ButtonFill type="edit" text="edit" on:click={() => 프로젝트_편집버튼_클릭(project)}>
							<Icon__edit/>
						</PEI_ButtonFill>
						<PEI_ButtonFill theme="secondary" text="delete" on:click={() => 프로젝트_삭제버튼_클릭(project)}>
							<Icon__delete/>
						</PEI_ButtonFill>
					</div>
				{/if}
			</section>
		{/if}

		{#if notices.length >= 1 }
		<section class="pei-list">
			<PEI_TitleBar type="notice" text="notice">
				<Icon__notice/>
			</PEI_TitleBar>

			{#each notices as notice}
				<PEI_NoticeListRow {notice} on:click={() => NoticeDetail(notice)}/>
			{/each}
		</section>
		{/if}

		{#if schedules.length >= 1 }
		<PEI_TitleBar type="schedule" text="schedule">
			<Icon__schedule/>
		</PEI_TitleBar>

		<section class="pei-list">
			<PEI_Schedule__Group {schedules}/>
		</section>
		{/if}

	</PEI_LayoutMain>
</UIPage>