<script>
import PEI_ListRowEmpty from "../../molecules/PEI_List/PEI_ListRowEmpty.svelte";
import Icon__add from "../../atoms/icons/Icon__add.svelte";
import PEI_Button from "../../atoms/PEI_Button/PEI_Button.svelte";
import {dispatch} from "../../../1px/dataLayer";
import {createDateFormat} from "../../../1px/fp/date.js";
import {_새_스케줄_추가하기, _스케줄_상세보기} from "../../../store/actions.js";
import PEI_ScheduleListRow from "../../molecules/PEI_List/PEI_ScheduleListRow.svelte";
import PEI_SectionBar from "../../atoms/PEI_Bar/PEI_SectionBar.svelte";

const AddSchedule = (date) => dispatch(_새_스케줄_추가하기(date));
const ScheduleDetail = (data) => dispatch(_스케줄_상세보기(data));


////
const groupFormat = createDateFormat("yyyy-mm-dd")
const format = createDateFormat("mm월 dd일")
const dayNameFormat = createDateFormat("ddd:ko요일")

export let start = null;
export let end = null;
export let schedules = [];
let grouped_schedules;

const group = (schedules, start, end) => {
  const map = {};

  if (start && end) {
    const date = new Date(start);
    do {
      const key = groupFormat(date)
      map[key] = map[key] || [];
      date.setDate(date.getDate() + 1);
    } while (+date < end)
  }

  schedules.forEach(schedule => {
    const key = groupFormat(schedule.start_datetime)
    if (start && end) {
      map[key] && map[key].push(schedule)
    }
    else {
      map[key] = map[key] || [];
      map[key].push(schedule)
    }
  })

  const grouped_schedules = Object.keys(map).map(key => {
    const date = new Date(key);
    const title = format(date);
    const dayName = dayNameFormat(date);
    const theme = dayName === "일요일" ? "secondary"
      : dayName === "토요일" ? "primary"
        : ""

    return {
      id: key,
      title,
      dayName,
      theme,
      schedules: map[key]
    }
  });

  return grouped_schedules;
}

$: grouped_schedules = group(schedules, start, end);
</script>


{#each grouped_schedules as group (group.id)}
  <PEI_SectionBar type="calendar" theme={group.theme} on:click={()=>AddSchedule(group.id)}>
    <div>{group.title}</div>
    <space flex/>
    <div>{group.dayName}</div>
  </PEI_SectionBar>

  {#each group.schedules as schedule}
    <PEI_ScheduleListRow {schedule} on:click={() => ScheduleDetail(schedule)}/>
  {:else}
    <PEI_ListRowEmpty {group}/>
  {/each}

  <!--	<PEI_Button type="add" text="add schedule" color on:click={()=>AddSchedule(group.id)}>-->
  <!--		<Icon__add/>-->
  <!--	</PEI_Button>-->

{/each}

<style type="text/stylus">
.row__add
  display none

.list__row--wrap:hover
.list__row--wrap:active
  .row__empty
    display none

  .row__add
    display block
</style>
