import {BehaviorSubject, Observable, Subject} from "../observable"
import {__cloneObject} from "../fp"

const noop = () => {}
const itself = (value) => value

/// @TODO: 로그용, 추후 미들웨어로 분리할 것! [2020. 5. 27]
const state = window.state = Object.create(null)
const memo = Object.create(null)
const memo2 = Object.create(null)


const safe_equal = (a, b) => a === b && Object(a) !== a

export function reducer(initValue, path, callback) {
  if (path && memo2[path]) return memo2[path]

  const r = new Observable(observer => {
    console.warn("[reducer]", path, initValue)
    const ref$ = ref(initValue, path)

    /// @FIXME: reducer에서 createEffect는 component가 아닌 reducer의 생애주기에 따라 가도록 임시 개발. 추후 제대로 개발하자.
    let subSubscriptions = []
    const rollback = Observable.prototype.createEffect.enter(s => {
      // subSubscriptions.push(s)
    })
    const destroyCallback = callback(ref$) || noop
    rollback()
    const s = ref$.createEffect(observer)

    return () => {
      console.warn("[reducer::finalize]", path, r.value, subSubscriptions)
      destroyCallback()
      s.unsubscribe()
      for (const __s of subSubscriptions) __s.unsubscribe()
    }

  }).tap(value => r.value = value).shareReplay(1)

  r.value = initValue
  return memo2[path] = r
}


let writableIndex = 0

export function ref(initValue, path) {
  if (path && memo[path]) return memo[path]

  const resetValue = __cloneObject(initValue)
  const writable = (initValue === undefined ? new BehaviorSubject() : new BehaviorSubject(initValue))
  writable.path = path
  writable.set = (value) => writable.next(value)
  writable.reset = () => writable.next(resetValue)
  writable.update = (callback = itself) => writable.next(callback(writable.value))


  /// @TODO: 로그용, 추후 미들웨어로 분리할 것! [2020. 5. 27]
  if (path && !memo[path]) {

    // @FIXME: 임시 log flag
    if (Observable.enableLog) {

      if (path.charAt(0) !== "#") {
        writable
          .distinctUntilChanged(safe_equal)
          .trace("#" + (++writableIndex) + " " + path)
          .tap(value => state[path] = value)
          .finalize(() => {
            console.warn('[ref::finalize]', path)
            delete state[path]
          })
          .createEffect()
      }
    }
  }

  return memo[path] = writable
}

Observable.prototype.writeTo = function (writable, pipe = itself) {
  const unwrap_thunk = (value) => {
    let callback = pipe

    if (typeof callback !== "function") return callback
    callback = callback(value)

    if (typeof callback !== "function") return callback
    return callback(writable.value)
  }

  const subject = new Subject()

  this.createEffect((value) => {
    writable.set(unwrap_thunk(value))
    subject.next(value)
  })

  return subject
}