<script>
export let color;
</script>

<span>
	<span class="tag {color}">
		<slot/>
	</span>
</span>


<style type="text/stylus">
.tag
	display inline-block
	height 2rem
	padding .1rem .3rem
	font-size 1.2rem
	border-radius .5rem
	margin-right 1rem
	color $white
	background $black

.primary
	background $primary
.secondary
	background $secondary

</style>