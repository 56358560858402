<script>
import PEI_Icon__Company from "../PEI_Icon__Company.svelte";
import Icon__planit from "../../atoms/icons/Icon__planit.svelte";
import Icon__element from "../../atoms/icons/Icon__element.svelte";
import {createDateFormat} from "../../../1px/fp/date";

export let schedule;

const timeFormat = createDateFormat("hh:ii");
</script>


<section class="list__row status-{schedule.status}" on:click>
	{#if !schedule.has_notime}
		<div class="row__time">
			{timeFormat(schedule.start_datetime)}
			~ {timeFormat(schedule.end_datetime)}
		</div>
	{:else}
		<div class="row__time">-</div>
	{/if}

	<h1 class="row__content">
		<div hbox>
			<PEI_Icon__Company company={schedule.company}/>
			<space size="0.4"/>
		</div>

		<div class="content__text">
			{#if !schedule.is_individual}
				<span class="{schedule.is_important_event ? 'text__highlight' : ''}">
					<strong>{schedule.project && schedule.project.name || '-'}</strong>
					/ <strong primary>{schedule.in_charge && schedule.in_charge.name || '-'}</strong>
					/ <span> {schedule.title || '-'}</span>
				</span>
			{:else if schedule.is_individual}
				<span class="{schedule.is_important_event ? 'text__highlight' : ''}">
					<strong primary>{schedule.in_charge && schedule.in_charge.name || '-'}</strong>
					/ <span> {schedule.title || '-'}</span>
				</span>
			{/if}
		</div>
	</h1>
</section>


<style type="text/stylus">
.list__row
	hbox(start)
	min-height 37px
	font-size 1.4rem
	line-height 2.4rem
	padding .55rem 1.2rem
	cursor pointer
	border-top 1px dashed $light-gray
	margin-top -2px

	.row__time
		/*width 4.6rem*/
		width 10rem
		font-weight bold
		color $secondary

	.row__content
		flex()
		hbox(start)

		.content__icon
			pack()
			margin-top .1rem
			margin-right .4rem
			display none

		&[company='planit'] .content__icon--planit
			display block

		&[company='element'] .content__icon--element
			display block

		.content__text
			flex()

	&[marked]
		.text__highlight
			padding .15rem 0

	.text__highlight
		background $highlight-primary

.list__row.status-완료
	.row__time
	.context__text
	.content__text >*
	.content__text strong
		color $muted !important



</style>