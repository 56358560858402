<script>
import {dispatch, on} from "1px";
import {select} from "../../1px/dataLayer";
import {_GO} from "../../1px/plugins/router.js";
import {account$} from "../../store/features/account/store.account.js";
import UIPage from "../../components/ui/UIPage.svelte";
import {_내정보_편집하기} from "../../store/actions.js";

import PEI_ButtonFill from "../../components/atoms/PEI_Button/PEI_ButtonFill.svelte";
import PEI_TitleBar from "../../components/atoms/PEI_Bar/PEI_TitleBar.svelte";
import PEI_ListRowField from "../../components/molecules/PEI_List/PEI_ListRowField.svelte";
import PEI_LayoutMain from "../../components/organisms/PEI_LayoutMain.svelte";
import Icon__edit from "../../components/atoms/icons/Icon__edit.svelte";
import Icon__account from "../../components/atoms/icons/Icon__account.svelte";

let account;
$: account = select($account$)

const 내정보_편집버튼_클릭 = (account) => dispatch(_내정보_편집하기(account));

on(_내정보_편집하기)
	.dispatch(account => _GO("/my/edit", account.id))


</script>

<UIPage {...$$props}>
  <PEI_LayoutMain>

    <PEI_TitleBar type="myaccount" text="my account">
      <Icon__account color="white"/>
    </PEI_TitleBar>

    {#if account && account.id}
      <section class="pei-list">
        <PEI_ListRowField field="이름" status="readonly" theme>{account.name}</PEI_ListRowField>

        <PEI_ListRowField field="EMAIL" status="readonly" theme>{account.email}</PEI_ListRowField>

        <PEI_ListRowField field="직책" status="readonly" theme>{account.job}</PEI_ListRowField>

        {#each account.teams as team, index}
          <PEI_ListRowField field="{index === 0 ? '대표': '추가'}부서" status="readonly" theme>{team.name}</PEI_ListRowField>
        {/each}

        <PEI_ListRowField field="연락처" status="readonly" theme>{account.phone || '-'}</PEI_ListRowField>
      </section>
    {/if}

    <div inset>
      <PEI_ButtonFill type="edit" text="edit" on:click={() => 내정보_편집버튼_클릭(account)}>
        <Icon__edit/>
      </PEI_ButtonFill>
    </div>

  </PEI_LayoutMain>
</UIPage>