<script>
export let type = "";
export let color = "";
</script>

<svg class="icon__add" viewBox="0 0 12 12" {type} {color}>
	<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
		<g id="source" transform="translate(-227.000000, -3.000000)">
			<g id="icon-add" transform="translate(224.000000, 0.000000)">
				<polygon id="Bounds" points="0 0 18 0 18 18 0 18"></polygon>
				<polygon id="Icon" fill="#999"
						 points="14.25 9.75 9.75 9.75 9.75 14.25 8.25 14.25 8.25 9.75 3.75 9.75 3.75 8.25 8.25 8.25 8.25 3.75 9.75 3.75 9.75 8.25 14.25 8.25"></polygon>
			</g>
		</g>
	</g>
</svg>

<style type="text/stylus">
svg.icon__add
	width 1.2rem
	height 1.2rem

	&[type='big']
		width 1.4rem
		height 1.4rem

	&[color='white'] #Icon
		fill white
</style>