<script>
import {dispatch, on} from "1px";
import {onDestroy} from "svelte";
import {_BACK, onRoute} from "../../../1px/plugins/router.js";
import {_프로젝트_저장하기, _프로젝트_편집_취소하기} from "../../../store/actions.js";

import PEI_LayoutMain from "../../../components/organisms/PEI_LayoutMain.svelte";
import PEI_ButtonFill from "../../../components/atoms/PEI_Button/PEI_ButtonFill.svelte";
import PEI_TitleBar from "../../../components/atoms/PEI_Bar/PEI_TitleBar.svelte";
import PEI_ListRowField from "../../../components/molecules/PEI_List/PEI_ListRowField.svelte";
import Icon__save from "../../../components/atoms/icons/Icon__save.svelte";
import Icon__cancel from "../../../components/atoms/icons/Icon__cancel.svelte";
import Icon__project from "../../../components/atoms/icons/Icon__project.svelte";
import PEI_SectionBar from "../../../components/atoms/PEI_Bar/PEI_SectionBar.svelte";
import {_SAVE_PROJECT} from "../../../store/features/project/store.project.js";
import {data} from "../../../1px/plugins/graphql.js";
import {_QUERY_ACCOUNT} from "../../../store/features/account/store.account.js";
import {_QUERY_PROJECT_BY_ID_EDIT} from "../../../store/features/project/store.project.js";
import {store} from "../../../store/store.js";
import {_QUERY_PROJECT_BY_ID} from "../../../store/features/project/store.project.js";
import {select} from "../../../1px/dataLayer";
import UIPage from "../../../components/ui/UIPage.svelte";
import UIDatePicker from "../../../components/ui/UIDatePicker.svelte";

const 프로젝트_저장버튼_클릭 = (project) => dispatch(_프로젝트_저장하기(project));
const 취소버튼_클릭 = () => dispatch(_프로젝트_편집_취소하기());

on(_프로젝트_저장하기)
		.dispatch(project => _SAVE_PROJECT(project))

on(_SAVE_PROJECT.SUCCESS)
		.tap(() => alert('저장하였습니다.'))
		.dispatch(_BACK())

on(_프로젝트_편집_취소하기)
		.filter(() => confirm("취소하시겠습니까?"))
		.dispatch(_BACK());


///
export let id = null;
const account$ = store.account$;

let account;
let project = null;
let teams = [];

data
$: dispatch(_QUERY_PROJECT_BY_ID(id))
$: dispatch(_QUERY_PROJECT_BY_ID_EDIT())

$: account = $account$
$: teams = select($data, _QUERY_PROJECT_BY_ID_EDIT) || teams;

$: project = select($data, _QUERY_PROJECT_BY_ID, id) || project;
$: if (project) {
	project.status = project.status || "진행중";
}
</script>


<UIPage {...$$props}>
	<PEI_LayoutMain>
		<PEI_TitleBar type="project" text="project">
			<Icon__project/>
		</PEI_TitleBar>

        {#if project}
			<section class="pei-list">
				<div class="list__item">
					<PEI_SectionBar theme type>
						<div flex>기본 정보</div>
					</PEI_SectionBar>

					<PEI_ListRowField field="작성자" status="readonly" theme>{project.writer.name || account.name}</PEI_ListRowField>

					<!--					<PEI_ListRowField field="제작부서" status="editable" value theme>-->
					<!--						<select>-->
					<!--                            {#each companies as company}-->
					<!--								<option>{company}</option>-->
					<!--                            {/each}}-->
					<!--						</select>-->
					<!--					</PEI_ListRowField>-->

					<PEI_ListRowField field="제작팀" status="editable" value theme>
						<select bind:value={project.team.id}>
							<option>-- 제작팀 선택 --</option>
                            {#each teams as team}
								<option value={team.id}>{team.name}</option>
                            {/each}
						</select>
					</PEI_ListRowField>


					<PEI_ListRowField field="광고주" status="editable" value theme>
						<input type="text" name="" bind:value={project.client}/>
					</PEI_ListRowField>

					<PEI_ListRowField field="작품명 *" status="editable" value theme>
						<input type="text" name="" bind:value={project.name}/>
					</PEI_ListRowField>

					<PEI_ListRowField field="상태" status="editable" value theme>
						<select bind:value={project.status}>
							<option value="진행중">진행중</option>
							<option value="완료">완료</option>
						</select>
					</PEI_ListRowField>

					<PEI_ListRowField field="색상" status="editable" value theme>
						<input type="color" name="" bind:value={project.color}/>
					</PEI_ListRowField>

					<PEI_ListRowField field="생성일" status="editable" value theme>
						<UIDatePicker bind:value={project.created_at}/>
					</PEI_ListRowField>
				</div>

				<div class="list__item">
					<PEI_SectionBar theme="secondary" type>
						<div flex>대행사</div>
					</PEI_SectionBar>

					<PEI_ListRowField field="대행사" status="editable" value theme>
						<input type="text" name="" bind:value={project.agency.name}/>
					</PEI_ListRowField>

					<PEI_ListRowField field="CD" status="editable" value theme>
						<input type="text" name="" bind:value={project.agency.CD}/>
					</PEI_ListRowField>

					<PEI_ListRowField field="CW" status="editable" value theme>
						<input type="text" name="" bind:value={project.agency.CW}/>
					</PEI_ListRowField>

					<PEI_ListRowField field="AE" status="editable" value theme>
						<input type="text" name="" bind:value={project.agency.AE}/>
					</PEI_ListRowField>

					<PEI_ListRowField field="제작관리" status="editable" value theme>
						<input type="text" name="" bind:value={project.agency.production_management}/>
					</PEI_ListRowField>
				</div>

				<div class="list__item">
					<PEI_SectionBar theme="primary" type>
						<div flex>담당자</div>
					</PEI_SectionBar>

					<PEI_ListRowField field="기획실장" status="editable" value theme>
						<input type="text" name="" bind:value={project.PIC.planning_director}/>
					</PEI_ListRowField>

					<PEI_ListRowField field="EP *" status="editable" value theme>
						<input type="text" name="" bind:value={project.PIC.EP}/>
					</PEI_ListRowField>

					<PEI_ListRowField field="PD" status="editable" value theme>
						<input type="text" name="" bind:value={project.PIC.PD}/>
					</PEI_ListRowField>

					<PEI_ListRowField field="감독" status="editable" value theme>
						<input type="text" name="" bind:value={project.PIC.director}/>
					</PEI_ListRowField>

					<PEI_ListRowField field="조감독" status="editable" value theme>
						<input type="text" name="" bind:value={project.PIC.assistant_director}/>
					</PEI_ListRowField>
				</div>

				<div class="list__item">
					<PEI_SectionBar theme="primary" type>
						<div flex>스탭</div>
					</PEI_SectionBar>

					<PEI_ListRowField field="촬영감독" status="editable" value theme>
						<input type="text" name="" bind:value={project.staff.photography_director}/>
					</PEI_ListRowField>

					<PEI_ListRowField field="조명감독" status="editable" value theme>
						<input type="text" name="" bind:value={project.staff.gaffer_director}/>
					</PEI_ListRowField>

					<PEI_ListRowField field="그립팀" status="editable" value theme>
						<input type="text" name="" bind:value={project.staff.grip_team}/>
					</PEI_ListRowField>

					<PEI_ListRowField field="로케이션팀" status="editable" value theme>
						<input type="text" name="" bind:value={project.staff.location_team}/>
					</PEI_ListRowField>

					<PEI_ListRowField field="아트실장" status="editable" value theme>
						<input type="text" name="" bind:value={project.staff.art_director}/>
					</PEI_ListRowField>

					<PEI_ListRowField field="헤어실장" status="editable" value theme>
						<input type="text" name="" bind:value={project.staff.hair_director}/>
					</PEI_ListRowField>

					<PEI_ListRowField field="메이크업실장" status="editable" value theme>
						<input type="text" name="" bind:value={project.staff.makeup_director}/>
					</PEI_ListRowField>

					<PEI_ListRowField field="의상실장" status="editable" value theme>
						<input type="text" name="" bind:value={project.staff.dress_director}/>
					</PEI_ListRowField>

					<PEI_ListRowField field="모델 에이전시" status="editable" value theme>
						<input type="text" name="" bind:value={project.staff.model_agency}/>
					</PEI_ListRowField>

					<PEI_ListRowField field="모델(셀럽)" status="editable" value theme>
						<input type="text" name="" bind:value={project.staff.model_celeb}/>
					</PEI_ListRowField>

					<PEI_ListRowField field="특수효과팀" status="editable" value theme>
						<input type="text" name="" bind:value={project.staff.special_effect}/>
					</PEI_ListRowField>
				</div>

				<div class="list__item">
					<PEI_SectionBar theme="primary" type>
						<div flex>후반</div>
					</PEI_SectionBar>

					<PEI_ListRowField field="편집" status="editable" value theme>
						<input type="text" name="" bind:value={project.after.editor}/>
					</PEI_ListRowField>

					<PEI_ListRowField field="2D" status="editable" value theme>
						<input type="text" name="" bind:value={project.after.two_d}/>
					</PEI_ListRowField>

					<PEI_ListRowField field="3D" status="editable" value theme>
						<input type="text" name="" bind:value={project.after.three_d}/>
					</PEI_ListRowField>

					<PEI_ListRowField field="ART" status="editable" value theme>
						<input type="text" name="" bind:value={project.after.art}/>
					</PEI_ListRowField>

					<PEI_ListRowField field="녹음실" status="editable" value theme>
						<input type="text" name="" bind:value={project.after.recording}/>
					</PEI_ListRowField>

					<PEI_ListRowField field="오디오PD" status="editable" value theme>
						<input type="text" name="" bind:value={project.after.audio_pd}/>
					</PEI_ListRowField>

					<PEI_ListRowField field="D.I" status="editable" value theme>
						<input type="text" name="" bind:value={project.after.di}/>
					</PEI_ListRowField>
				</div>

				<div class="list__item">
					<PEI_SectionBar theme="primary" type>
						<div flex>해외촬영스탭</div>
					</PEI_SectionBar>

					<PEI_ListRowField field="해외 프로덕션" status="editable" value theme>
						<input type="text" name="" bind:value={project.global.production}/>
					</PEI_ListRowField>

					<PEI_ListRowField field="국가" status="editable" value theme>
						<input type="text" name="" bind:value={project.global.nation}/>
					</PEI_ListRowField>
				</div>

				<div class="list__item" inset>
					<PEI_ButtonFill type="save" text="save" on:click={() => 프로젝트_저장버튼_클릭(project)}>
						<Icon__save/>
					</PEI_ButtonFill>
					<PEI_ButtonFill theme="mute" text="back" on:click={취소버튼_클릭}>
						<Icon__cancel/>
					</PEI_ButtonFill>
				</div>
			</section>
        {/if}
	</PEI_LayoutMain>
</UIPage>