<script>
import {dispatch, on} from "1px";
import Icon__more from "../../../components/atoms/icons/Icon__more.svelte";
import {createDateFormat} from "../../../1px/fp/date.js";
import {_BACK, _GO} from "../../../1px/plugins/router.js";
import {_스케줄_삭제하기, _스케줄_편집하기, _프로젝트_상세페이지로_이동} from "../../../store/actions.js";
import {_DELETE_SCHEDULE} from "../../../store/features/schedule/store.schedule.js";

import PEI_LayoutMain from "../../../components/organisms/PEI_LayoutMain.svelte";
import PEI_ButtonFill from "../../../components/atoms/PEI_Button/PEI_ButtonFill.svelte";
import PEI_TitleBar from "../../../components/atoms/PEI_Bar/PEI_TitleBar.svelte";
import PEI_ListRowField from "../../../components/molecules/PEI_List/PEI_ListRowField.svelte";
import PEI_ListRowFieldTextarea from "../../../components/molecules/PEI_List/PEI_ListRowFieldTextarea.svelte";
import Icon__edit from "../../../components/atoms/icons/Icon__edit.svelte";
import Icon__delete from "../../../components/atoms/icons/Icon__delete.svelte";
import Icon__schedule from "../../../components/atoms/icons/Icon__schedule.svelte";
import {data} from "../../../1px/plugins/graphql.js";
import {_QUERY_SCHEDULE_BY_ID} from "../../../store/features/schedule/store.schedule.js";
import Tag from "../../../components/atoms/icons/Tag.svelte";
import UIPage from "../../../components/ui/UIPage.svelte";
import {select} from "../../../1px/dataLayer";

const dateFormat = createDateFormat("yyyy년 mm월 dd일");
const timeFormat = createDateFormat("hh시 ii분")

const 프로젝트_이동 = (project) => dispatch(_프로젝트_상세페이지로_이동(project));

export let id = null
let schedule = null;

data
$: dispatch(_QUERY_SCHEDULE_BY_ID(id))
$: schedule = select($data, _QUERY_SCHEDULE_BY_ID, id) || schedule;


// Action
const 스케줄_편집_버튼_클릭 = (schedule) => dispatch(_스케줄_편집하기(schedule));
const 스케줄_삭제_버튼_클릭 = (schedule) => dispatch(_스케줄_삭제하기(schedule));

on(_스케줄_편집하기)
  .dispatch(schedule => _GO("/schedules/:id/edit", schedule.id))

on(_스케줄_삭제하기)
  .filter(() => confirm("삭제하시겠습니까?"))
  .dispatch(schedule => _DELETE_SCHEDULE(schedule.id))

on(_DELETE_SCHEDULE.SUCCESS)
  .tap(() => alert("삭제되었습니다."))
  .dispatch(_BACK())

on(_DELETE_SCHEDULE.FAILURE)
  .tap(error => alert(error.message))
  .createEffect();

on(_프로젝트_상세페이지로_이동).dispatch(project => _GO("/projects/:id", project.id))
</script>

<UIPage {...$$props}>
  <PEI_LayoutMain>
    <PEI_TitleBar type="schedule" text="schedule">
      <Icon__schedule/>
    </PEI_TitleBar>

    {#if schedule}
      <section class="pei-list">
        <div class="list__item">
          <!--				<PEI_ListRowField field="구분" status="readonly" theme>-->
          <!--					{#if schedule.is_individual}-->
          <!--						<Tag color>개인</Tag>-->
          <!--					{:else}-->
          <!--						<Tag color>공통</Tag>-->
          <!--					{/if}-->
          <!--				</PEI_ListRowField>-->

          <PEI_ListRowField field="프로젝트" status="readonly" theme>
            {#if !schedule.is_individual}
            <div class="hbox pointer" on:click={() => 프로젝트_이동(schedule.project)}>
              <div class="flex"><strong>{schedule.project.name || '-'}</strong></div>
              <div>
                <icon class="content__icon">
                  <Icon__more color="black"/>
                </icon>
              </div>
            </div>
            {:else}
              <strong>개인 스케줄</strong>
            {/if}
          </PEI_ListRowField>

<!--          <PEI_ListRowField field="프로젝트" status="readonly" theme>-->
<!--            {#if !schedule.is_individual}-->
<!--              <strong>{schedule.project.name}</strong>-->
<!--            {:else}-->
<!--              <strong>개인 스케줄</strong>-->
<!--            {/if}-->
<!--          </PEI_ListRowField>-->

          <PEI_ListRowField field="제목" status="readonly" theme>{schedule.title || '-'}</PEI_ListRowField>

          {#if schedule.project && schedule.project.id}
            <PEI_ListRowField field="부서/직책" status="readonly" theme>{schedule.project.team.name || '-'} / {schedule.in_charge.job || '-'}</PEI_ListRowField>
          {/if}

          <PEI_ListRowField field="담당자" status="readonly" theme="primary">
            <strong>{schedule.in_charge.name || '-'}</strong> / {schedule.in_charge.email || '-'}
          </PEI_ListRowField>

          <PEI_ListRowField field="시작일" status="readonly" theme="bold">{dateFormat(schedule.start_datetime)}</PEI_ListRowField>

          {#if schedule.has_end_datetime}
            <PEI_ListRowField field="종료일" status="readonly" theme="bold">{dateFormat(schedule.end_datetime)}</PEI_ListRowField>
          {/if}

          {#if !schedule.has_notime}
            <PEI_ListRowField field="시작시각" status="readonly" theme="secondary">{timeFormat(schedule.start_datetime)}</PEI_ListRowField>
            <PEI_ListRowField field="종료시각" status="readonly" theme="secondary">{timeFormat(schedule.end_datetime)}</PEI_ListRowField>
          {/if}

          <!--					<PEI_ListRowField field="형광펜" status="editable">{schedule.is_important_event}</PEI_ListRowField>-->

          <PEI_ListRowFieldTextarea field="내용" status="readonly">
            {schedule.desc || '-'}
          </PEI_ListRowFieldTextarea>

          <p class="note">최근 수정 : {schedule.modified_by.name} {dateFormat(schedule.modified_at)}</p>
        </div>

        {#if schedule.is_editable}
          <section inset>
            <PEI_ButtonFill theme text="edit" on:click={() => 스케줄_편집_버튼_클릭(schedule)}>
              <Icon__edit/>
            </PEI_ButtonFill>
            <PEI_ButtonFill theme="secondary" text="delete" on:click={() => 스케줄_삭제_버튼_클릭(schedule)}>
              <Icon__delete/>
            </PEI_ButtonFill>
          </section>
        {/if}
      </section>
    {/if}
  </PEI_LayoutMain>
</UIPage>