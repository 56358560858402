<script>
export let field;
export let status;
</script>


<div class="list__row" {status}>
	<div class="row__field">{field}</div>
	<div class="row__value">
		<div class="value__text">
			<slot/>
		</div>
		<div class="value__inputbox">
			<slot/>
		</div>
	</div>
</div>


<style type="text/stylus">
.list__row
	min-height 9rem
	font-size 1.4rem
	line-height 2.4rem
	border-bottom 1px dashed $light-gray

	.row__field
		width 7rem
		font-weight bold
		margin .6rem 0
		margin-left 1.2rem

	.row__value
		margin-left 1.2rem
		padding-top .1rem
		padding-right 1.2rem
		white-space pre-line
		flex()

		.value__inputbox
			min-height 3rem

	&[status='editable']
		.value__text
			display none

	&[status='readonly']
		.value__inputbox
			display none


</style>