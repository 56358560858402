<script>
export let color
</script>

<svg class="icon__more" viewBox="0 0 8 12" {color}>
	<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
		<g id="source" transform="translate(-229.000000, -21.000000)">
			<g id="icon-more" transform="translate(224.000000, 18.000000)">
				<polygon id="Bounds" points="0 0 18 0 18 18 0 18"></polygon>
				<polygon id="Icon" fill="#fff"
						 points="7 3 5.59 4.41 10.17 9 5.59 13.59 7 15 13 9"></polygon>
			</g>
		</g>
	</g>
</svg>

<style type="text/stylus">
svg.icon__more
	width 0.8rem
	height 1.2rem

	&[color='black']
		#Icon
			fill $black
</style>