<script>
import "./1px/plugins/router.js"
import PEI_Loading from "./components/molecules/PEI_Loading.svelte";
import ErrorLog from "./components/ErrorLog.svelte";
import PEI_SignIn from "./components/pages/account/PEI_SignIn.svelte";
import {onMount} from "svelte";
import UIPage from "./components/ui/UIPage.svelte";
import {dispatch} from "./1px";
import {account$} from "./store/features/account/store.account.js";

import schema from "../graphql/schema/_schema.graphql";
import schema2 from "../graphql/schema/_schema.notice.graphql";
import schema3 from "../graphql/schema/_schema.project.graphql";
import schema4 from "../graphql/schema/_schema.schedule.graphql";
import schema5 from "../graphql/schema/_schema.user.graphql";
import {action_GraphQL} from "./1px/plugins/graphql.js";
import {Router} from "@sveltech/routify";
import {routes} from "@sveltech/routify/tmp/routes";

/// @FIXME:
action_GraphQL.schema(schema);
action_GraphQL.schema(schema2);
action_GraphQL.schema(schema3);
action_GraphQL.schema(schema4);
action_GraphQL.schema(schema5);
onMount(() => dispatch("APP_START"))
</script>

{#if !$account$}
  <UIPage title="로그인">
    <PEI_SignIn/>
  </UIPage>
{:else}
  <Router {routes}/>
{/if}

<!--<PEI_Loading/>-->
<ErrorLog/>

<style type="text/stylus" global>
@import "./css/style"
</style>