<script>
export let theme;
export let text;
</script>


<pei-button-fill {theme} on:click>
  <space flex/>

  <section hbox>
    <icon class="pei-button-fill__icon">
      <slot/>
    </icon>
    <h1 class="pei-button-fill__title">{text}</h1>
  </section>

  <space flex/>
</pei-button-fill>


<style type="text/stylus">
pei-button-fill
  display block
  position relative
  height 3.6rem
  font-weight bold
  font-size 1.4rem
  text-align center
  text-transform uppercase
  color $white
  background $primary
  border-radius .5rem
  cursor pointer
  hbox()

  .pei-button-fill__icon
  .pei-button-fill__title
    margin 0 0.4rem

  .pei-button-fill__icon
    pack()

  :global(& + &)
    margin-top 1.2rem

  &:after
    content ''
    display block
    border-radius .5rem
    layer()
    position absolute
    background rgba(0, 0, 0, 0.1)
    display none

  &:hover
  &:active
    &:after
      display block


[theme='secondary']
  background $secondary

[theme='mute']
  background $gray

</style>