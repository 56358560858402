<script>
import {createDateFormat} from "../../1px/fp/date.js";

export let value;

const updateFrom = (date) => {
	const ret = new Date(value || null);
	date = new Date(date);

	ret.setFullYear(date.getFullYear())
	ret.setMonth(date.getMonth())
	ret.setDate(date.getDate())
	return +ret;
}

const format = createDateFormat("yyyy-mm-dd")
let date = format(value);
$: value = updateFrom(date);
</script>

<input type="date" bind:value={date}/>
