<script>
import {dispatch} from "../../../1px/dataLayer";
import {createDateFormat} from "../../../1px/fp/date.js";
import {_GO, _GO_REPLACE} from "../../../1px/plugins/router.js";
import {_새_스케줄_추가하기, _스케줄_상세보기} from "../../../store/actions.js";

const AddSchedule = (date) => dispatch(_새_스케줄_추가하기(date));
const ScheduleDetail = (data) => dispatch(_스케줄_상세보기(data));

////// ----- grouped schedule
const groupFormat = createDateFormat("yyyy-mm-dd")
const format = createDateFormat("d")
const dayNameFormat = createDateFormat("ddd:ko요일")

export let start = null;
export let end = null;
export let schedules = [];
let grouped_schedules;

let today;
let today_date;
let today_month;
today = new Date();
today_month = today.getMonth();
today_date = today.getDate();
today = today.setHours(0, 0, 0, 0);


const group = (schedules) => {
  const map = {};

  if (start && end) {
    const date = new Date(start);
    do {
      const key = groupFormat(date)
      map[key] = map[key] || [];
      date.setDate(date.getDate() + 1);
    } while (+date < end)
  }

  schedules.forEach(schedule => {
    const key = groupFormat(schedule.start_datetime)
    map[key] = map[key] || [];
    map[key].push(schedule)
  })

  const grouped_schedules = Object.keys(map).map(key => {
    const date = new Date(key);
    const title = format(date);
    const dayName = dayNameFormat(date);
    const theme = dayName === "일요일" ? "secondary"
      : dayName === "토요일" ? "primary"
        : ""

    return {
      id: key,
      title,
      dayName,
      theme,
      schedules: map[key]
    }
  });

  return grouped_schedules;
}

$: grouped_schedules = group(schedules);

const 일별스케쥴로이동 = (date) => dispatch(_GO("/calendar/#:date", date))
console.log("------------->", start);
</script>

{#each grouped_schedules as group (group.id)}
  <div class="day relative">
    <div class="day__num pack {group.title == today_date ? 'today' : ''}" on:click={() => 일별스케쥴로이동(group.id)}>
      <div class="flex pack">
        <div class="num__circle pack">{group.title}</div>
      </div>
      <div class="num__button flex pack" on:click={()=>AddSchedule(group.id)}>+</div>
    </div>
    <section class="tasks">
      {#each group.schedules as schedule}
        <div class="task {schedule.start_datetime < today ? 'passed': ''} {schedule.project.status == '완료' ? 'passed' : ''}" style="background: {schedule.project.color};" on:click={() => ScheduleDetail(schedule)}>[{schedule.project.name}] {schedule.title}</div>
      {/each}
    </section>
  </div>
{/each}

<style type="text/stylus">
.day
  position relative
  font-size 1.2rem
  text-align center
  border-right 1px solid #ddd
  border-bottom 1px solid #ddd
  padding-bottom 1rem
  z-index 1
  cursor pointer

  &:nth-of-type(7n + 7)
    border-right 0


// --- 다섯 개의 행
for $i in 0..4
  .day:nth-of-type(n + {$i*7+1}):nth-of-type(-n+{($i+1)*7})
    grid-row $i + 1

// --- 일곱 개의 열
for $i in 1..7
  .day:nth-of-type(7n + {$i})
    grid-column $i / $i


.day__num
  position relative
  padding .4rem 0
  color #333
  font-weight bold

  .num__circle
    width 2.4rem
    height 2.4rem
    border-radius 100%

    &:hover
    &:active
      background var(--hover-bg)

  .num__button
    width 2.4rem
    height 2.4rem
    border-radius 100%

    &:hover
    &:active
      background var(--hover-bg)


+desktop()
  .day__num
    .num__button
      visibility hidden
      position absolute
      right 1rem

    &:hover
    &:active
      .num__button
        visibility visible


.day__num.today
  .num__circle
    color #fff
    background #009BE0

.day--disabled
  color #98a0a6
  background-color #f9f9fa
  cursor not-allowed


// ---- tasks
.tasks
  //position absolute
  grid-column 2
  grid-row 3
  //left 0
  width 100%
  padding-right 0.4rem

.task
  padding .2rem
  padding-right 0
  position relative
  font-size 1.2rem
  text-align left
  white-space nowrap
  overflow hidden
  border-radius 0.2rem
  color #4a4a4a
  background #eee

  &:after
    content ''
    display none
    position absolute
    width 100%
    height 100%
    top 0
    left 0
    background rgba(255, 255, 255, 0.5)

  &.passed:after
    display block

  +mobile-and-tablet()
    font-size 1rem

  & + &
    margin-top .2rem
</style>