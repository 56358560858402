<script>
import {dispatch, on} from "1px";
import Icon__checkbox__admin from "../../components/atoms/icons/admin/Icon__checkbox__admin.svelte";
import Icon__edit__admin from "../../components/atoms/icons/admin/Icon__edit__admin.svelte";
import Icon__close__admin from "../../components/atoms/icons/admin/Icon__close__admin.svelte";
import Icon__save__admin from "../../components/atoms/icons/admin/Icon__save__admin.svelte";
import Adm_button__simple from "../../components/admin/Adm_button__simple.svelte";
import UIAdminPopup from "../../components/ui/UIAdminPopup.svelte";
import UIAdmin from "../../components/ui/UIAdmin.svelte";
import {ref, select} from "../../1px/dataLayer";
import {Observable} from "../../1px/observable";
import {data} from "../../1px/plugins/graphql.js";
import {_GO, onRoute} from "../../1px/plugins/router.js";
import {_QUERY_USERS} from "../../store/features/account/store.account.js";

import {store} from "../../store/store.js";
import {_유저_검색하기, _유저_상세페이지로_이동, _유저_필터적용하기, _새_유저_저장하기} from "../../store/actions.js";

import UIActivity from "../../components/ui/UIPage.svelte";
import PEI_ButtonFill from "../../components/atoms/PEI_Button/PEI_ButtonFill.svelte";
import PEI_TitleBar from "../../components/atoms/PEI_Bar/PEI_TitleBar.svelte";
import PEI_SearchBar from "../../components/atoms/PEI_Bar/PEI_SearchBar.svelte";
import PEI_ListRowBoard from "../../components/molecules/PEI_List/PEI_ListRowBoard.svelte";
import PEI_LayoutMain from "../../components/organisms/PEI_LayoutMain.svelte";
import Icon__notice from "../../components/atoms/icons/Icon__notice.svelte";
import PEI_Inputbox from "../../components/atoms/PEI_Inputbox.svelte";
import PEI_InputSelect from "../../components/atoms/PEI_InputSelect.svelte";
import Icon__filter from "../../components/atoms/icons/Icon__filter.svelte";
import PEI_NoticeListRow from "../../components/molecules/PEI_List/PEI_NoticeListRow.svelte";
import UIPage from "../../components/ui/UIPage.svelte";
import Icon__add from "../../components/atoms/icons/Icon__add.svelte";
import Icon__account from "../../components/atoms/icons/Icon__account.svelte";
import PEI_UserListRow from "../../components/molecules/PEI_List/PEI_UserListRow.svelte";

const 유저_클릭 = (user) => dispatch(_유저_상세페이지로_이동(user));
const 유저_추가버튼_클릭 = () => dispatch(_새_유저_저장하기);


/// Effector
on(_새_유저_저장하기)
  .dispatch(_GO("/admin[desktop]/new"))

on(_유저_상세페이지로_이동)
  .dispatch(user => _GO("/admin[desktop]/:id", user.id))

on(_유저_필터적용하기)
  .todo("유저 목록에서 검색어 정보와 일치하는 정보 찾기 => #유저_검색하기(search$)")
  .createEffect();

on(_유저_검색하기)
  .todo("_유저_검색하기")
  .createEffect();


const filter_search = ref("");
const filter_project = ref("");
// Observable.combineLatest(filter_search, filter_project).dispatch(_유저_필터적용하기)


/// Selector
let users = [];

let COMPANIES = [];
let TEAMS = [];
let JOB = [];

let companyId;
let team_id;
let job;

data
$: dispatch(_QUERY_USERS)
$: users = select($data, _QUERY_USERS) || users;

</script>


<UIAdmin {...$$props}>
  <section class="pei-list">
    <!--      <PEI_TitleBar type="user" text="staff">-->
    <!--        <Icon__account color="white"/>-->
    <!--      </PEI_TitleBar>-->

    <!--      <div class="list__item">-->
    <!--          <PEI_UserListRow user={row} on:click={() => 유저_클릭(row)}/>-->

    <table class="admin__table">
      <thead>
      <tr>
        <th style="width: 36px">#</th>
        <th style="width: 150px">소속</th>
        <th style="width: 100px">이름</th>
        <th flex style="text-align: left;">이메일(ID)</th>
        <th style="width: 100px">직책</th>
        <th style="width: 150px">부서</th>
        <th style="width: 100px">등록일</th>
      </tr>
      </thead>

      <tbody>

      {#each users as row}
        <tr on:click>
          <td><Icon__checkbox__admin/></td>
          <td>{row.companies && row.companies.length && row.companies.map(c => c.name).join(", ") || '-'}</td>
          <td>{row.name || '-'}</td>
          <td style="text-align: left;"><h1><span clickable on:click>{row.email || '-'}</span></h1></td>
          <td>{row.job}</td>
          <td>{row.teams && row.teams.length && row.teams.map(team => team.name).join(", ") || '-'}</td>
          <td>2020-20-20</td>
        </tr>
      {/each}

      </tbody>
    </table>


    <!--      </div>-->


    <!--      <div inset>-->
    <!--        <PEI_ButtonFill text="add staff" on:click={유저_추가버튼_클릭}>-->
    <!--          <Icon__add color="white" type/>-->
    <!--        </PEI_ButtonFill>-->
    <!--      </div>-->

  </section>
</UIAdmin>

<!--<UIAdminPopup>-->
<!--  <header class="popup__header">-->
<!--    <space flex/>-->
<!--    <h1 hbox><Icon__edit__admin/><space size="0.6"/>EDIT MODE</h1>-->
<!--    <space flex/>-->
<!--    <div class="header__icon" on:click><Icon__close__admin/></div>-->
<!--  </header>-->

<!--  <section class="popup__body&#45;&#45;wrap">-->
<!--    <section class="popup__body">-->

<!--      <div class="form">-->
<!--        <div class="form__fields">-->
<!--          <h1 class="fields__title">유저 정보</h1>-->

<!--          <div class="fields__item">-->
<!--            <h1>이름</h1>-->
<!--            <input type="text"/>-->
<!--          </div>-->

<!--          <div class="fields__item">-->
<!--            <h1>이메일(ID)</h1>-->
<!--            <input type="text"/>-->
<!--          </div>-->

<!--          <div class="fields__item">-->
<!--            <h1>비밀번호</h1>-->
<!--            <input type="text"/>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="form__fields">-->
<!--          <h1 class="fields__title">소속 정보</h1>-->

<!--          <div class="fields__item">-->
<!--            <h1>소속</h1>-->
<!--            <input type="text"/>-->
<!--          </div>-->

<!--          <div class="fields__item">-->
<!--            <h1>직책</h1>-->
<!--            <input type="text"/>-->
<!--          </div>-->

<!--          <div class="fields__item">-->
<!--            <h1>부서</h1>-->
<!--            <input type="text"/>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </section>-->
<!--  </section>-->

<!--  <footer class="popup__footer">-->
<!--    <Adm_button__simple on:click>-->
<!--      <Icon__save__admin/><space size="0.6"/>SAVE-->
<!--    </Adm_button__simple>-->
<!--  </footer>-->
<!--</UIAdminPopup>-->


<style type="text/stylus">
.admin__table
  width 100%
  border-spacing 0

  th
    height 3.2rem
    font-weight bold
    border-bottom 1px solid #000

    [icon]
      margin auto

  td
    font-weight regular
    min-height 5rem
    border-bottom 1px solid #000
    padding .8rem
    text-align center

    h1
      font-weight medium

  td[left], th[left]
    padding .8rem
    text-align left

  tbody
    tr
      &:hover
        background-color #F9F9F9

      &[selected]
        background-color #EEEEEE

  [clickable]
    &:hover
      cursor pointer
      text-decoration underline


</style>
