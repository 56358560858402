<script>
export let type = null;
export let text;
</script>

<pei-titlebar>
	<space flex/>

	<!--	<section class={type}>-->
	<icon class="pei-titlebar__icon">
		<slot/>
	</icon>
	<h1 class="pei-titlebar__title">{text}</h1>
	<!--	</section>-->

	<space flex/>
</pei-titlebar>


<style type="text/stylus">
pei-titlebar
	hbox()
	height 2rem
	min-height 2rem
	font-size 1.4rem
	font-weight bold
	text-align center
	color var(--title-bar__color)
	background var(--title-bar__background)
	position sticky
	top 0
	z-index 9999

.pei-titlebar__icon
	display block
	margin 0 .4rem
	pack()

.pei-titlebar__title
	text-transform uppercase

</style>