<script>
import Icon__cancel from "../../components/atoms/icons/Icon__cancel.svelte";
import Icon__add from "../../components/atoms/icons/Icon__add.svelte";
import PEI_ButtonFill from "../../components/atoms/PEI_Button/PEI_ButtonFill.svelte";
import {_BACK, _GO_REPLACE, _ROUTE, location$} from "../../1px/plugins/router.js";
import PEI_Schedule__Group from "../../components/organisms/schedules/PEI_Schedule__Group.svelte";
import PEI_InputSelect from "../../components/atoms/PEI_InputSelect.svelte";
import PEI_Inputbox from "../../components/atoms/PEI_Inputbox.svelte";
import Icon__filter from "../../components/atoms/icons/Icon__filter.svelte";
import PEI_Schedule__Calendar from "../../components/organisms/schedules/PEI_Schedule__Calendar.svelte";
import Icon__schedule from "../../components/atoms/icons/Icon__schedule.svelte";
import PEI_TitleBar from "../../components/atoms/PEI_Bar/PEI_TitleBar.svelte";
import PEI_LayoutMain from "../../components/organisms/PEI_LayoutMain.svelte";
import {on, dispatch, select} from "../../1px/dataLayer";
import {data} from "../../1px/plugins/graphql.js";
import {_QUERY_SCHEDULES} from "../../store/features/schedule/store.schedule.js";
import UIPage from "../../components/ui/UIPage.svelte";
import {__today, createDateFormat} from "../../1px/fp/date.js";
import {store} from "../../store/store.js";

/// Select
let PROJECTS = [];
let TEAMS = [];
let STAFF = [];
let YEARS = Array(new Date().getFullYear() - 2017 + 2).fill(new Date().getFullYear() + 1).map((year, index) => ({id: year - index, name: (year - index) + "년"}))
let MONTHS = Array(12).fill(0).map((__, index) => ({id: index, name: (index + 1) + "월"}))

let account = store.account$;

let projectId;
let team_id = $account.team.id
let staff_id;

// 달력 --- 요일명
let dayNames = ["일", "월", "화", "수", "목", "금", "토"];
// let schedules = [];

// 달력 --- 오늘 날짜 + 0:0:0:0
// 달력 --- 첫 진입시: year/올해, month/이번달
let today = __today();
let year = today.getFullYear();
let month = today.getMonth();
$: today = new Date(year, month, 1)

// 달력 --- 기준달 앞뒤의 1주
let week_before = [];
let week_after = [];

const dateFormat = createDateFormat("yyyy년 m월")

// 달력 --- 달력그리기
function 달력그리기(month, year) {
  // func: 달의 길이 or 마지막 날 구하기
  const getDaysInMonth = (month, year) => new Date(year, month + 1, 0).getDate();
  const weekBeforeLength = (num) => num

  // --- 현재 달 그리기
  let current_days = getDaysInMonth(month, year);
  let days = new Array(current_days)
  for (let i = 0; i < current_days; i++) {
    days[i] = i + 1
  }

  let first_day = new Date(year, month);
  let first_weekday = first_day.getDay();
  let last_month_days = getDaysInMonth(month - 1, year);

  // 지난달 마지막주의 일수
  week_before = []
  let week_before_length = weekBeforeLength(first_weekday);
  for (let i = last_month_days - week_before_length + 1; i <= last_month_days; i++) {
    week_before.push(i);
  }
  week_before = week_before

  // 다음달 첫주의 일수
  week_after = []
  let next_month = new Date(year, month + 1);
  let next_month_first_day = next_month.getDay();
  for (let i = 1; i <= 7 - next_month_first_day; i++) {
    week_after.push(i);
  }
  week_after = week_after
}


let start;
let end;
$: start = new Date(year, month, 1);
$: end = new Date(year, month + 1);

$: dispatch(_QUERY_SCHEDULES(projectId, team_id, staff_id, +start, +end))
$: PROJECTS = select($data, _QUERY_SCHEDULES, "projects") || PROJECTS;
$: TEAMS = select($data, _QUERY_SCHEDULES, "teams") || TEAMS;
$: STAFF = select($data, _QUERY_SCHEDULES, "staff") || STAFF;
$: schedules = select($data, _QUERY_SCHEDULES, "schedules") || schedules;


// 달력그리기
달력그리기(month, year)
on(_QUERY_SCHEDULES.SUCCESS)
  .tap(() => 달력그리기(month, year))
  .createEffect()

const 지난달로이동 = () => month -= 1
const 다음달로이동 = () => month += 1


// 날짜 표기
$: hash = $location$.hash
$: startDay = new Date(hash)
$: endDay = new Date(+new Date(startDay).setDate(startDay.getDate() + 1))


const 캘린더로_이동 = () => dispatch(_BACK())
</script>

<svelte:window on:popstate={onhashchange}/>

<UIPage {...$$props}>
  <PEI_LayoutMain>

    {#if !hash}
      <PEI_TitleBar type="filter" text="filter">
        <Icon__filter/>
      </PEI_TitleBar>

      <section inset bold>
        <PEI_Inputbox>
          <PEI_InputSelect name="PROJECT" options={PROJECTS} bind:value={projectId}/>
        </PEI_Inputbox>

        <space size="0.8"/>

        <div hbox>
          <div flex>
            <PEI_Inputbox>
              <PEI_InputSelect name="TEAM" options={TEAMS} type="{$account.job === '대표이사' ? 'more-info' : ''}" bind:value={team_id}/>
            </PEI_Inputbox>
          </div>
          <space size="0.8"/>

          <div flex>
            <PEI_Inputbox>
              <PEI_InputSelect name="STAFF" options={STAFF} bind:value={staff_id}/>
            </PEI_Inputbox>
          </div>
        </div>

        <space size="0.8"/>

        <div hbox>
          <div flex>
            <PEI_Inputbox>
              <PEI_InputSelect name="YEAR" options={YEARS} bind:value={year}/>
            </PEI_Inputbox>
          </div>
          <space size="0.8"/>
          <div class="months" flex>
            <PEI_Inputbox>
              <PEI_InputSelect name="MONTH" options={MONTHS} bind:value={month}/>
            </PEI_Inputbox>
          </div>
        </div>
      </section>
    {/if}


    <PEI_TitleBar type="schedule" text="schedule">
      <Icon__schedule/>
    </PEI_TitleBar>

    {#if !hash}
      <section class="calendar" vbox>
        <!--        <section class="calendar__header hbox">-->
        <!--          <space flex/>-->
        <!--          <div class="header__button circle" on:click={지난달로이동}>-->
        <!--            <i class="material-icons pack height-full">keyboard_arrow_left</i>-->
        <!--          </div>-->
        <!--          <h1 class="header__month flex">{dateFormat(today)}</h1>-->
        <!--          <div class="header__button circle" on:click={다음달로이동}>-->
        <!--            <i class="material-icons pack height-full">keyboard_arrow_right</i>-->
        <!--          </div>-->
        <!--          <space flex/>-->
        <!--        </section>-->

        <section class="calendar__day-name" hbox>
          {#each dayNames as dayName}
            <div flex class="day-name">{dayName}</div>
          {/each}
        </section>

        <div flex>
          <section class="calendar__body">
            <!--    @NOTE: 지난달 -->
            {#each week_before as day}
              <div class="day day--disabled">
                <div class="day__num"></div>
              </div>
            {/each}

            <!--    @NOTE: 이번달 -->
            <PEI_Schedule__Calendar {start} {end} {schedules}/>

            <!--    @NOTE: 다음달 -->
            {#each week_after as day}
              <div class="day day--disabled">
                <div class="day__num"></div>
              </div>
            {/each}
          </section>
        </div>
      </section>
    {:else}
      <section class="pei-list">
        <PEI_Schedule__Group start={startDay} end={endDay} {schedules}/>
      </section>

      <section inset>
        <PEI_ButtonFill theme="mute" text="back" on:click="{캘린더로_이동}">
          <Icon__cancel/>
        </PEI_ButtonFill>
      </section>

    {/if}

  </PEI_LayoutMain>
</UIPage>

<style type="text/stylus">
.calendar
  width 100%
  height 100%
  overflow hidden
  background #fff

.calendar__body
  display grid
  width 100%
  height 100%
  grid-template-columns repeat(7, minmax(0, 1fr))
  grid-template-rows repeat(5, minmax(10rem, auto))

.calendar__header
  text-align center
  border-bottom 1px solid #dadce0
  padding 1rem 0

  .header__button
    width 5rem
    height @width
    font-size 2rem
    cursor pointer
    overflow hidden

  .header__month
    margin 0
    font-size 1.4rem

.calendar__day-name .day-name
  font-size 1.2rem
  text-transform uppercase
  color #99a1a7
  text-align center
  border-right 1px solid #dadce0
  padding-top 1rem

.day
  position relative
  font-size 1.2rem
  text-align center
  border-right 1px solid #ddd
  border-bottom 1px solid #ddd
  padding-bottom 1rem
  z-index 1
  cursor pointer

  &:nth-of-type(7n + 7)
    border-right 0


// --- 여섯 개의 행
for $i in 0..5
  .day:nth-of-type(n + {$i*7+1}):nth-of-type(-n+{($i+1)*7})
    grid-row $i + 1

// --- 일곱 개의 열
for $i in 1..7
  .day:nth-of-type(7n + {$i})
    grid-column $i / $i

.day--disabled
  color #98a0a6
  background-color #f9f9fa
  cursor not-allowed

</style>