<script>
import {currentRoute$, onRoute} from "../1px/plugins/router.js";

export let title = null;
export let path = null;

let params = {title, path}
let visible = !path;

if (path) {
  onRoute(path);

  currentRoute$
    .filter(route => route)
    .map(route => route.path === path)
    .createEffect(value => visible = value)

  currentRoute$
    .filter(route => route)
    .filter(route => route.path === path)
    .map(route => route.params)
    .createEffect(value => params = {title, path, ...value})
}
</script>

{#if visible}
  <slot {params}/>
{/if}