<script>
import PEI_Icon__Company from "../PEI_Icon__Company.svelte";
import Icon__planit from "../../atoms/icons/Icon__planit.svelte";
import Icon__element from "../../atoms/icons/Icon__element.svelte";
import {createDateFormat} from "../../../1px/fp/date";

export let notice = {};

const dateFormat = createDateFormat("mm/dd");
</script>


<section class="list__row" on:click>

  <div hbox>
    <PEI_Icon__Company company={notice.company}/>
    <space size="0.4"/>
  </div>

  <div class="row__content">
    <div class="content__caption">
      <div class="caption__name">
        <span class="name__proj">{notice.project && notice.project.name || '-'}</span> /
        <strong primary>{notice.writer && notice.writer.name || '-'}</strong>
      </div>
      <div class="caption__date">{dateFormat(notice.created_at) || '-'}</div>
    </div>
    <div class="content__title">{notice.title || '-'}</div>
  </div>
</section>


<style type="text/stylus">
.list__row
  font-size 1.4rem
  padding .6rem 1.2rem
  border-bottom 1px dashed $light-gray
  cursor pointer
  hbox(start)

  .row__icon
    margin-top .2rem
    margin-right .8rem
    display none

  &[company='planit'] .row__icon-planit
    display block

  &[company='element'] .row__icon-element
    display block

  .row__content
    flex()

    .content__caption
      hbox()
      height 2.4rem

      .caption__name
        flex()
        font-weight bold
        height 100%
        overflow hidden

        .name__proj[project='공지사항']
          color $secondary

      .caption__date
        color $gray
        font-weight 700

    .content__title
      line-height 2.4rem
      text-align Justify

.list__row:last-of-type
  border-bottom 0

</style>