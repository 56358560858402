<script>
import {dispatch, on} from "1px";
import {select} from "../../1px/dataLayer";
import Icon__delete from "../../components/atoms/icons/Icon__delete.svelte";
import UIPage from "../../components/ui/UIPage.svelte";
import {store} from "../../store/store.js";
import {data} from "../../1px/plugins/graphql.js";

import PEI_ButtonFill from "../../components/atoms/PEI_Button/PEI_ButtonFill.svelte";
import PEI_TitleBar from "../../components/atoms/PEI_Bar/PEI_TitleBar.svelte";
import PEI_ListRowField from "../../components/molecules/PEI_List/PEI_ListRowField.svelte";
import PEI_LayoutMain from "../../components/organisms/PEI_LayoutMain.svelte";
import Icon__save from "../../components/atoms/icons/Icon__save.svelte";
import Icon__cancel from "../../components/atoms/icons/Icon__cancel.svelte";
import Icon__account from "../../components/atoms/icons/Icon__account.svelte";
import Select from "../../components/atoms/Select.svelte";

import {_BACK, onRoute} from "../../1px/plugins/router.js";
import {_SAVE_USER, _QUERY_COMPANIES, _QUERY_TEAMS, _QUERY_USER, _DELETE_USER} from "../../store/features/account/store.account.js";
import {_유저_저장하기, _유저_저장_취소하기, _유저_삭제하기} from "../../store/actions.js";

let COMPANIES = store.companies;
let PLANIT = store.planit;
let ELEMENT = store.element;

const 취소버튼_클릭 = () => dispatch(_유저_저장_취소하기());
const 삭제버튼_클릭 = (user) => dispatch(_유저_삭제하기(user.id));
const 저장클릭 = (user, companies, teams, password) => {
  user.companies = companies.filter(id => id).map(id => ({id}))
  user.teams = teams.filter(team => team && team.id)
  if (password) {
    user.password = password;
  }
  dispatch(_유저_저장하기(user));
}

on(_유저_저장하기)
  .dispatch(user => _SAVE_USER(user))

on(_유저_삭제하기)
  .dispatch(user => _DELETE_USER(user))

on(_DELETE_USER.SUCCESS)
  .tap(() => alert('삭제하였습니다.'))
  .dispatch(_BACK());


on(_SAVE_USER.SUCCESS)
  .tap(() => alert('추가하였습니다.'))
  .dispatch(_BACK());

on(_SAVE_USER.FAILURE)
  .tap(error => alert(error.message))
  .createEffect();

on(_유저_저장_취소하기)
  .filter(() => confirm("취소하시겠습니까?"))
  .dispatch(_BACK());


export let id;

let user = {
  companies: [{id: null}],
  teams: [{id: null}]
};

let password = "";
let companies = [];
let teams = [];

let user_companies = [];
let user_teams = [];

$: dispatch(_QUERY_COMPANIES())
$: dispatch(_QUERY_TEAMS(user_companies))

$: user = select($data, _QUERY_USER, id) || user;
$: companies = select($data, _QUERY_COMPANIES) || companies;
// $: teams = select($data, _QUERY_TEAMS) || teams;

let p_teams = [];
let e_teams = [];
p_teams = PLANIT.teams;
e_teams = ELEMENT.teams;

const castArray = (a) => Array.isArray(a) ? a : [];

on(_QUERY_USER.SUCCESS)
  .map(data => data.user)
  .tap(user => user_companies = castArray(user.companies).map(u => u.id))
  .tap(user => user_teams = castArray(user.teams).map(u => ({id: u.id})))
  .createEffect();

$: user_teams = castArray(user_teams).filter((u, index, A) => index === A.length - 1 || u.id)
$: if (user_teams.length === 0) user_teams = [{id: null}]
$: if (user_teams[user_teams.length - 1].id) user_teams = [...user_teams, {id: null}]
$: console.log("user_teams", user_teams)
</script>

<UIPage {...$$props}>
  <PEI_LayoutMain>
    <PEI_TitleBar type="myaccount" text="staff">
      <Icon__account color="white"/>
    </PEI_TitleBar>

    <section class="pei-list">
      <div class="list__item">
        <PEI_ListRowField field="EMAIL" status="editable" value theme>
          <input type="text" bind:value={user.email}/>
        </PEI_ListRowField>

        <PEI_ListRowField field="PASSWORD" status="editable" value theme>
          <input type="password" bind:value={password}/>
        </PEI_ListRowField>

        <PEI_ListRowField field="성명" status="editable" value theme>
          <input type="text" bind:value={user.name}/>
        </PEI_ListRowField>

        {#each companies as company, index}
          <PEI_ListRowField field={index === 0 ? "회사" : ""} value theme>
            <div><label><input type="checkbox" bind:group={user_companies} value={company.id}/> {company.name}</label>
            </div>
          </PEI_ListRowField>
        {/each}

        <!--  ELEMENT : 직책, 부서    -->
        {#if user_companies.length < 2 && user_companies[0] === 'TF9h1Ou1uwlhGCIYPsOs'}
          <PEI_ListRowField field="직책" status="editable" value theme>
            <Select bind:value={user.job} options={ELEMENT.jobs} title="- 직책 선택 -"/>
          </PEI_ListRowField>

          {#each user_teams as user_team, index}
            <PEI_ListRowField field={index === 0 ? '대표부서' : '부서 #' + (index+1)} status="editable" value theme>
              <Select bind:value={user_team.id} options={e_teams} title="- 부서 선택 -"/>
            </PEI_ListRowField>
          {/each}

          <!--  PLANIT : 직책, 부서    -->
        {:else if user_companies.length < 2 && user_companies[0] === 'pgEBGYaZ9s3J1mCEnKGM'}
          <PEI_ListRowField field="직책" status="editable" value theme>
            <Select bind:value={user.job} options={PLANIT.jobs} title="- 직책 선택 -"/>
          </PEI_ListRowField>

          {#each user_teams as user_team, index}
            <PEI_ListRowField field={index === 0 ? '대표부서' : '부서 #' + (index+1)} status="editable" value theme>
              <Select bind:value={user_team.id} options={p_teams} title="- 부서 선택 -"/>
            </PEI_ListRowField>
          {/each}

          <!--  ELEMENT, PLANIT : 직책, 부서    -->
        {:else}
          <PEI_ListRowField field="직책" status="editable" value theme>
            <select bind:value={user.job}>
              <optgroup label="element pictures">
                <option value={null}>- 직책 선택 -</option>
                {#each ELEMENT.jobs as option}
                  <option value={option.id || option.name}>{option.name}</option>
                {/each}
              </optgroup>
              <optgroup label="plan'it production">
                <option value={null}>- 직책 선택 -</option>
                {#each PLANIT.jobs as option}
                  <option value={option.id || option.name}>{option.name}</option>
                {/each}
              </optgroup>
            </select>
          </PEI_ListRowField>

          {#each user_teams as user_team, index}
            <PEI_ListRowField field={index === 0 ? '대표부서' : '부서 #' + (index+1)} status="editable" value theme>
              <select bind:value={user_team.id}>
                <optgroup label="element pictures">
                  <option value={null}>- 부서 선택 -</option>
                  {#each e_teams as option}
                    <option value={option.id || option.name}>{option.name}</option>
                  {/each}
                </optgroup>

                <optgroup label="plan'it production">
                  <option value={null}>- 부서 선택 -</option>
                  {#each p_teams as option}
                    <option value={option.id || option.name}>{option.name}</option>
                  {/each}
                </optgroup>
              </select>
            </PEI_ListRowField>
          {/each}
        {/if}

      </div>
    </section>


    <div inset>
      <PEI_ButtonFill type="save" text="save" on:click="{() => 저장클릭(user, user_companies, user_teams, password)}">
        <Icon__save/>
      </PEI_ButtonFill>

      {#if user.id}
        <PEI_ButtonFill theme="secondary" text="delete" on:click={() => 삭제버튼_클릭(user)}>
          <Icon__delete/>
        </PEI_ButtonFill>
      {/if}

      <PEI_ButtonFill theme="mute" text="back" on:click="{취소버튼_클릭}">
        <Icon__cancel/>
      </PEI_ButtonFill>
    </div>

  </PEI_LayoutMain>
</UIPage>