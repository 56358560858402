<script>
export let type;
export let color;
</script>

<svg class="icon__project" viewBox="0 0 20 16" {type} {color}>
	<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
		<g id="source" transform="translate(-202.000000, -4.000000)">
			<g id="icon-project" transform="translate(200.000000, 0.000000)">
				<polygon id="Bounds" points="0 -5.68434189e-14 24 -5.68434189e-14 24 24 0 24"></polygon>
				<path d="M18,4 L19.82,7.64 C19.9,7.8 19.78,8 19.6,8 L17.62,8 C17.24,8 16.89,7.79 16.73,7.45 L15,4 L13,4 L14.82,7.64 C14.9,7.8 14.78,8 14.6,8 L12.62,8 C12.24,8 11.89,7.79 11.73,7.45 L10,4 L8,4 L9.82,7.64 C9.9,7.8 9.78,8 9.6,8 L7.62,8 C7.24,8 6.89,7.79 6.72,7.45 L5,4 L4,4 C2.9,4 2,4.9 2,6 L2,18 C2,19.1 2.9,20 4,20 L20,20 C21.1,20 22,19.1 22,18 L22,5 C22,4.45 21.55,4 21,4 L18,4 Z"
					  id="Path" fill="#ffffff" fill-rule="nonzero"></path>
			</g>
		</g>
	</g>
</svg>

<style type="text/stylus">
svg.icon__project
	width 2 * 0.8rem
	height 1.6 * 0.8rem

	&[type='big']
		width 2rem
		height 1.6rem

	&[color='black'] path
		fill $black
</style>