<script>
import {__cloneObject} from "../../1px/fp";
import PEI_LayoutMain from "../../components/organisms/PEI_LayoutMain.svelte";
import PEI_ButtonFill from "../../components/atoms/PEI_Button/PEI_ButtonFill.svelte";
import PEI_TitleBar from "../../components/atoms/PEI_Bar/PEI_TitleBar.svelte";
import PEI_ListRowField from "../../components/molecules/PEI_List/PEI_ListRowField.svelte";
import Icon__save from "../../components/atoms/icons/Icon__save.svelte";
import Icon__cancel from "../../components/atoms/icons/Icon__cancel.svelte";
import UIPage from "../../components/ui/UIPage.svelte";

import {dispatch, on, Observable} from "1px";
import Icon__account from "../../components/atoms/icons/Icon__account.svelte";
import {_QUERY_ACCOUNT, _SAVE_USER, account$} from "../../store/features/account/store.account.js";
import {_BACK, onRoute} from "../../1px/plugins/router.js";
import {_CANCEL_MYACCOUNT, _SAVE_MYACCOUNT} from "../../store/actions.js";


///
const 내정보_저장버튼_클릭 = (account, password, password_confirm) => {
  if (!account.name) return alert("이름을 입력해주세요.");
  if (password && password !== password_confirm) return alert("비밀번호가 일치하지 않습니다.");
  dispatch(_SAVE_USER({...account, password}));
}

on(_SAVE_USER.SUCCESS)
  .dispatch(_QUERY_ACCOUNT());

on(_SAVE_USER.SUCCESS)
  .tap(() => alert('저장하였습니다.'))
  .dispatch(_BACK());

on(_SAVE_USER.FAILURE)
  .tap(error => alert(error.message))
  .createEffect();


///
const 취소버튼_클릭 = () => dispatch(_CANCEL_MYACCOUNT());

on(_CANCEL_MYACCOUNT)
  .filter(() => confirm("취소하시겠습니까?"))
  .dispatch(_BACK());


let account = {};
let password = "";
let password_confirm = "";

$: if (account.id !== $account$.id) {
  account = __cloneObject($account$)
}
</script>


<UIPage {...$$props}>
  <PEI_LayoutMain>
    <PEI_TitleBar type="account" text="my account">
      <Icon__account color="white"/>
    </PEI_TitleBar>

    <section class="pei-list">
      <div class="list__item">
        <PEI_ListRowField field="이름" status="editable" value theme>
          <input type="text" bind:value={account.name}/>
        </PEI_ListRowField>

        <PEI_ListRowField field="EMAIL" status="readonly" value theme>{account.email}</PEI_ListRowField>

        <PEI_ListRowField field="PASSWORD" status="editable" value theme>
          <input type="password" bind:value={password}/>
        </PEI_ListRowField>

        <PEI_ListRowField field="PASSWORD CONFIRM" status="editable" value theme>
          <input type="password" bind:value={password_confirm}/>
        </PEI_ListRowField>
      </div>

      <div class="list__item" inset>
        <PEI_ButtonFill type="save" text="save" on:click={() => 내정보_저장버튼_클릭(account, password, password_confirm)}>
          <Icon__save/>
        </PEI_ButtonFill>
        <PEI_ButtonFill theme="mute" text="back" on:click={취소버튼_클릭}>
          <Icon__cancel/>
        </PEI_ButtonFill>
      </div>
    </section>
  </PEI_LayoutMain>
</UIPage>
