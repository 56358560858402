<script>
import {dispatch, on} from "1px";
import {createDateFormat} from "../../1px/fp/date.js";
import {_GO} from "../../1px/plugins/router.js";
import {_MY_ACCOUNT} from "../../store/actions.js";
import {account$} from "../../store/features/account/store.account.js";

import Icon__menu from "../atoms/icons/Icon__menu.svelte";
import Icon__menu_active from "../atoms/icons/Icon__menu_active.svelte";
import {_BACK} from "../../1px/plugins/router";

export let state = "";

const go_nav = () => dispatch(_GO("/nav"));
const go_main = () => dispatch(_GO("/"));
const back = () => dispatch(_BACK());
const MyAccount = () => dispatch(_MY_ACCOUNT());

const today = createDateFormat(`<b>m</b>월 <b>d</b>일 <b>ddd:ko</b>요일`)(new Date());

let account = {};
$: account = $account$ || account;

on(_MY_ACCOUNT)
	.dispatch(_GO("/my"))

</script>

<header class="pei-header" {state}>


	{#if account.companies && account.companies.length < 2}
		<div class="logo {account.companies && account.companies[0].name}" on:click={go_main}></div>
	{:else}
		<div class="logo" on:click={go_main}></div>
	{/if}

	<div flex class="header__account">
		<div>{@html today}</div>
        {#if account && account.id}
			<div class="account__name">
				{#if account.team.name}
					{account.team.name}
				{/if}
				<strong>{account.name}</strong> {account.job}
			</div>
        {/if}
	</div>

	<icon class="menu" inactive on:click={go_nav}>
		<Icon__menu/>
	</icon>

	<icon class="menu" active on:click={back}>
		<Icon__menu_active/>
	</icon>

</header>


<style type="text/stylus">
.pei-header
	display block
	position relative
	min-height 7.4rem
	border-bottom 1px solid var(--border__color)
	hbox()

	.logo
		width 12.8rem
		height 4.6rem
		margin-left 2rem
		background-image url('/imgs/logo-admin.svg')
		background-repeat no-repeat
		background-size 12.8rem 4.6rem
		cursor pointer

		&.element_pictures
			width 12.8rem
			height 1.6rem
			background-image url('/imgs/logo-element.svg')
			background-size 12.8rem 1.6rem

		&.planit_production
			width 9.4rem
			height 3.2rem
			background-image url('/imgs/logo-planit.svg')
			background-size 9.4rem 3.2rem

	.header__account
		text-align right

		b
			font-weight bold

		.account__name
			font-size 1.2rem
			margin-top .2rem
			cursor pointer

	icon.menu
		padding 1.6rem
		font-size 2.4rem
		margin-left 0.6rem
		cursor pointer

		&[active]
			display none

/* 로그인 전 : 로고를 제외한 다른 요소 안보임 */
.pei-header[state='no-account']
	.header__account, icon
		display none

/* 팝업메뉴를 활성화시켰을 때 : 메뉴 아이콘 바뀜 */
.pei-header[state='active']
	icon[inactive]
		display none

	icon[active]
		display block

</style>