import {__localStorage} from "../../../1px/fp";
import accountQL from "./store.account.graphql"

import {ref, dispatch, on, reducer} from "../../../1px/dataLayer";
import {action_GraphQL} from "../../../1px/plugins/graphql.js";

/// Event
export const _QUERY_ACCOUNT = action_GraphQL(accountQL, "_QUERY_ACCOUNT");
export const _SIGN_IN = action_GraphQL(accountQL, "_SIGN_IN");
export const _LOGOUT = action_GraphQL(accountQL, "_LOGOUT");

export const _QUERY_USERS = action_GraphQL(accountQL, "_QUERY_USERS");
export const _QUERY_USER = action_GraphQL(accountQL, "_QUERY_USER");
export const _QUERY_COMPANIES = action_GraphQL(accountQL, "_QUERY_COMPANIES");
export const _QUERY_TEAMS = action_GraphQL(accountQL, "_QUERY_TEAMS");

export const _SAVE_USER = action_GraphQL(accountQL, "_SAVE_USER");
export const _DELETE_USER = action_GraphQL(accountQL, "_DELETE_USER");

/// State
const account = __localStorage.getItem("account", null)

export const account$ = reducer(account, "account", account$ => {

  account$
    .tap(__localStorage.setItem("account"))
    .createEffect()

  on(_LOGOUT.SUCCESS)
    .writeTo(account$, null)

  on(_QUERY_ACCOUNT.SUCCESS)
    .map(data => data.account)
    .filter(account => account.id)
    .writeTo(account$)
});


// on(_SIGN_IN.SUCCESS).createEffect(() => location.replace("/"))
on(_SIGN_IN.SUCCESS)
  .tap(() => location.reload())
  .createEffect()

on(_SIGN_IN.FAILURE)
  .tap(error => alert(error.message))
  .createEffect()

on(_LOGOUT.SUCCESS)
  .tap(() => location.replace("/"))
  .createEffect()

dispatch(_QUERY_ACCOUNT)