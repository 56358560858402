import {on, dispatch} from "../../../1px/dataLayer";
import {action_GraphQL} from "../../../1px/plugins/graphql.js";
import {_BACK, _GO} from "../../../1px/plugins/router.js";
import {_새_스케줄_추가하기, _스케줄_삭제하기, _스케줄_상세보기, _스케줄_편집하기} from "../../actions.js";

/// GQL
import GQL from "./store.schedule.graphql";

/// Event
export const _QUERY_SCHEDULES = action_GraphQL(GQL, "_QUERY_SCHEDULES");
export const _QUERY_SCHEDULE_BY_ID = action_GraphQL(GQL, "_QUERY_SCHEDULE_BY_ID");
export const _QUERY_SCHEDULE_BY_ID_EDIT = action_GraphQL(GQL, "_QUERY_SCHEDULE_BY_ID_EDIT");
export const _QUERY_USERS_IN_COMPANY = action_GraphQL(GQL, "_QUERY_USERS_IN_COMPANY");
export const _QUERY_USERS_IN_TEAM = action_GraphQL(GQL, "_QUERY_USERS_IN_TEAM");
export const _SAVE_SCHEDULE = action_GraphQL(GQL, "_SAVE_SCHEDULE");
export const _DELETE_SCHEDULE = action_GraphQL(GQL, "_DELETE_SCHEDULE");


on(_스케줄_상세보기)
  .dispatch(schedule => _GO("/schedules/:id", schedule.id))

on(_새_스케줄_추가하기)
  .dispatch(start_datetime => _GO("/schedules/add/:start_datetime", start_datetime))
