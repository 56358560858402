<script>
export let status = null;
</script>

<pei-inputbox {status}>
	<slot/>
</pei-inputbox>


<style type="text/stylus">
pei-inputbox
	display block
	position relative
	height 3.6rem
	font-size 1.4rem
	border 1px solid #999
	border-radius .5rem
	padding 0 1.2rem

	:global(& + &)
		margin-top .8rem

</style>