import {action_GraphQL} from "../../../1px/plugins/graphql.js";

import GQL from "./store.project.graphql";

export const _QUERY_MAIN = action_GraphQL(GQL, "_QUERY_MAIN");
export const _QUERY_PROJECTS = action_GraphQL(GQL, "_QUERY_PROJECTS");
export const _QUERY_PROJECT_BY_ID = action_GraphQL(GQL, "_QUERY_PROJECT_BY_ID");
export const _QUERY_PROJECT_BY_ID_EDIT = action_GraphQL(GQL, "_QUERY_PROJECT_BY_ID_EDIT");
export const _SAVE_PROJECT = action_GraphQL(GQL, "_SAVE_PROJECT");
export const _DELETE_PROJECT = action_GraphQL(GQL, "_DELETE_PROJECT");

