<script>
import {dispatch} from "../../../1px";
import {_BACK} from "../../../1px/plugins/router.js";
import PEI_Icon__Company from "../PEI_Icon__Company.svelte";
import Icon__planit from "../../atoms/icons/Icon__planit.svelte";
import Icon__element from "../../atoms/icons/Icon__element.svelte";
import Icon__more from "../../atoms/icons/Icon__more.svelte";
import {createDateFormat} from "../../../1px/fp/date";
import Tag from "../../atoms/icons/Tag.svelte";

export let project = {};

const dateFormat = createDateFormat("yyyy/mm/dd hh:ii:ss");

</script>


<section class="list__row status-{project.status}" on:click>
  <div class="row__content">
    <div hbox>
      <PEI_Icon__Company company={project.company}/>
      <space size="0.4"/>
    </div>

    <div class="content__text">
      {#if project.status === 'done'}
        <Tag color="primary">완료</Tag>
      {/if}
      <strong>{project.name || '-'}</strong> / <strong primary>EP {project.PIC && project.PIC.EP || '-'}</strong>
      {#if project.PIC.PD}
        / <span muted>PD {project.PIC && project.PIC.PD || '-'}</span>
      {/if}

      <!--			<div>company: {project.team.company.name}</div>-->
      <!--			<div>{dateFormat(project.created_at)}</div>-->
      <!--			<div>{project.status}</div>-->
    </div>
    <icon class="content__icon">
      <Icon__more color="black"/>
    </icon>
  </div>
</section>


<style type="text/stylus">
.list__row
  hbox(start)
  min-height 3.6rem
  padding .6rem 1.2rem
  font-size 1.4rem
  border-bottom 1px dashed $light-gray
  cursor pointer

  .row__icon
    margin-right .8rem

  .row__content
    flex()
    hbox(start)

    .content__text
      flex()
      margin-right .8rem

.list__row.status-완료
  .content__text
  .content__text > *
    color $muted !important

.list__row:last-of-type
  border-bottom 0
</style>