<script>
import PEI_Loading from "../molecules/PEI_Loading.svelte";
import {ref} from "../../1px/dataLayer";
import Route from "../Route.svelte";
import {config} from "../../config";

export let id = "";
export let path = "";
export let title = "";
export let theme = "";

export let params = ref([]);

let page = null;
let version = config.version;
</script>


<Route {path} bind:params>
  <section {title} {path} class="page" bind:this={page}>
    <main>
      <slot params={$params}/>
    </main>
  </section>
</Route>


<style type="text/stylus">
.page
  vbox()
  display flex
  justify-content start
  align-items center
  min-height 100%

main
  vbox()
  position relative
  overflow auto
  width 100%
  height 100%
  //width $width
  //height $height
  background #fff
  box-sizing content-box
  //border-style solid
  //border-color #000
  //border-radius 3rem
  //border-width 7rem 1.62rem
  //box-shadow 0 0 2rem rgba(0, 0, 0, .38), 0 2rem 2rem rgba(0, 0, 0, .38)
  font-family "Noto Sans CJK KR", "Helvetica Neue", 'dotum', sans-serif
</style>