<script>
import {dispatch, on} from "1px";
import {createDateFormat} from "../../../1px/fp/date.js";
import {data} from "../../../1px/plugins/graphql.js";
import {_BACK, _GO} from "../../../1px/plugins/router.js";
import {_공지사항_편집하기, _공지사항_삭제하기, _공지사항_편집가능_상태확인하기} from "../../../store/actions.js";

import PEI_LayoutMain from "../../../components/organisms/PEI_LayoutMain.svelte";
import PEI_ButtonFill from "../../../components/atoms/PEI_Button/PEI_ButtonFill.svelte";
import PEI_TitleBar from "../../../components/atoms/PEI_Bar/PEI_TitleBar.svelte";
import PEI_ListRowField from "../../../components/molecules/PEI_List/PEI_ListRowField.svelte";
import PEI_ListRowFieldTextarea from "../../../components/molecules/PEI_List/PEI_ListRowFieldTextarea.svelte";
import Icon__edit from "../../../components/atoms/icons/Icon__edit.svelte";
import Icon__delete from "../../../components/atoms/icons/Icon__delete.svelte";
import Icon__notice from "../../../components/atoms/icons/Icon__notice.svelte";
import {_DELETE_NOTICE, _QUERY_NOTICE_BY_ID} from "../../../store/features/notice/store.notice.js";
import UIPage from "../../../components/ui/UIPage.svelte";
import {select} from "../../../1px/dataLayer";

const dateFormat = createDateFormat("yyyy년 m월 dd일 hh:ii")

/// Dispatcher
const 공지사항_편집버튼_클릭 = (notice) => dispatch(_공지사항_편집하기(notice));
const 공지사항_삭제버튼_클릭 = (notice) => dispatch(_공지사항_삭제하기(notice));

on(_공지사항_편집하기)
	.dispatch(notice => _GO("/notices/:id/edit", notice.id))

on(_공지사항_삭제하기)
	.filter(() => confirm("삭제하시겠습니까?"))
	.dispatch(notice => _DELETE_NOTICE(notice.id))

on(_DELETE_NOTICE.SUCCESS)
	.tap(() => alert('삭제되었습니다.'))
	.dispatch(_BACK())

on(_DELETE_NOTICE.FAILURE)
	.tap(error => alert(error.message))
	.createEffect();


///
export let id = null;
let notice = null;

$: dispatch(_QUERY_NOTICE_BY_ID(id))
$: notice = select($data, _QUERY_NOTICE_BY_ID, id) || notice;
</script>


<UIPage {...$$props}>
	<PEI_LayoutMain>
		<PEI_TitleBar type="notice" text="notice">
			<Icon__notice/>
		</PEI_TitleBar>

		<section class="pei-list">
            {#if notice}
				<div class="list__item">
					<PEI_ListRowField field="프로젝트" status="readonly" theme>
						<strong color='secondary'>{notice.project.name || '-'}</strong>
					</PEI_ListRowField>

					<PEI_ListRowField field="제목" status="readonly" theme>
						<strong>{notice.title || '-'}</strong>
					</PEI_ListRowField>

					<PEI_ListRowField field="부서/직책" status="readonly" theme>
                        {notice.writer.team.name || '-'} / {notice.writer.job || '-'}
					</PEI_ListRowField>

					<PEI_ListRowField field="작성자" status="readonly" theme="primary">
						<strong>{ notice.writer.name || '-' } / { notice.writer.email || '-' }</strong>
					</PEI_ListRowField>

					<PEI_ListRowField field="등록일시" status="readonly" theme="bold">
                        {dateFormat(notice.created_at || '-')}
					</PEI_ListRowField>

					<PEI_ListRowFieldTextarea field="내용" status="readonly">
                        {notice.desc || '-'}
					</PEI_ListRowFieldTextarea>

					<p class="note">최근 수정 : {notice.modified_by.name || '-'} {dateFormat(notice.modified_at) || '-'}</p>
				</div>

				<section inset>
					<PEI_ButtonFill type="edit" text="edit" on:click={() => 공지사항_편집버튼_클릭(notice)}>
						<Icon__edit/>
					</PEI_ButtonFill>

					<PEI_ButtonFill theme="secondary" text="delete" on:click={() => 공지사항_삭제버튼_클릭(notice)}>
						<Icon__delete/>
					</PEI_ButtonFill>
				</section>
            {/if}
		</section>
	</PEI_LayoutMain>
</UIPage>





