import ApolloClient, {gql} from "apollo-boost";
import {InMemoryCache} from "apollo-cache-inmemory";

import {action, dispatch, on, ref} from "../dataLayer";

export const client = new ApolloClient({
  cache: new InMemoryCache()
});


const getByPath = (object, path) => path.reduce((o, key) => o && o[key], object)

const fillInDefault = (object, empty, path, path2) => {

  if (!object || typeof object !== "object") {
    return object;
  }

  if (object.length === 0) {
    return object;
  }

  for (const key of Object.keys(object)) {

    const _path = [...path, key];
    const _path2 = Array.isArray(object) ? path2 : [...path2, key];
    const value = object[key];

    if (!value) {
      object[key] = getByPath(empty, _path2);
      continue;
    }

    fillInDefault(value, empty, _path, _path2)
  }
  return object;
}


/// GraphQL로 query할 경우 빈 객체를 자동으로 만들어 주기!
const makeEmptySelection = (selections, target) => {
  if (!selections) return;

  for (const selection of selections) {
    const key = selection.name.value;
    target[key] = null;

    if (selection.selectionSet) {
      target[key] = {};
      makeEmptySelection(selection.selectionSet && selection.selectionSet.selections, target[key])
    }
  }

  return target;
}


const makeVariable = (def) => (...args) => {
  const variable = {};
  args.forEach((value, index) => {
    const [key] = def[index];
    if (key) variable[key] = value;
  })
  return variable;
}

const ObjectMap = (object, callback) => {
  if (object && typeof object === "object") return Object.fromEntries(Object.entries(object).map(callback).filter(v => v))

  console.log("?????????????????? object", callback);
  return object;
}

const filterVariableInputType = (defs) => {

  const filtering = (typeDef, value) => {

    console.log("filtering", typeDef, value);

    return ObjectMap(typeDef, ([key, def]) => {
      const typeDef2 = Type[def[0]]
      if (!typeDef2) return [key, value && value[key]];

      const isNonNullType = def.includes("NonNullType")
      if (isNonNullType) {

        console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@", value, key, value[key])

        if (value[key] === null || value[key] === undefined) {
          const error = new TypeError(`${key} is not a non-null.`)
          throw error
        }
      }

      const isListType = def.includes("ListType")
      if (isListType) {
        return [key, value[key].map(value => filtering(typeDef2, value))];
      }

      return [key, filtering(typeDef2, value[key])];
    })
  }

  const execDef = (variables) => (def) => {
    const [key, type] = def;
    const typeDef = Type[type];

    console.log("execDef", variables, def)


    /// @NOTE: ID type 처리 추가
    if (type === "ID") {
      return [key, variables.id]
    }

    if (!typeDef) return;

    /// @NOTE: InputType에 따라 filtering 한다.
    return [key, filtering(typeDef, variables[key])];
  }


  return (variables) => {
    const result = Object.fromEntries(defs.map(execDef(variables)).filter(v => v));
    return result;
  }
}


const Type = {};
const itself = a => a;
const graphqlErrorHandler = (error) => {
  throw error.graphQLErrors.find(e => e.message) || error
}

export const data = ref({}, "data");

export const action_GraphQL = (schema, name, pipe = itself) => {

  const query = schema.find(def => def.name.value === name);
  if (!query) throw new TypeError(`'${name}' has not been defined.`)

  // console.log(schema);
  // console.log(query);

  const operation = query.operation
  const variableDefinitions = query.variableDefinitions.map(parseVariable);

  // console.log(query.operation)
  // console.log(query.name.value)

  // const actionName = ["graphql", operation, name].join("/");


  const actionQL = action(name, makeVariable(variableDefinitions));
  const emptySelection = makeEmptySelection(query.selectionSet.selections, []);

  on(actionQL)
    .createEffect(() => {
      console.groupCollapsed(operation + " " + name + " ...")
      console.log(query.query)
      console.groupEnd()
    })


  /// Query Action
  if (operation === "query") {
    const watchQuery = client.watchQuery({query: gql(query.query)});

    on(actionQL)
      .mergeMap(variables => dispatch(actionQL.REQUEST, watchQuery.refetch(variables)
        .then(res => res.data)
        .then(data => fillInDefault(data, emptySelection, [], []))
        .catch(graphqlErrorHandler), variables)
        .pipe(pipe)
      )
      .tap(res => {
        if (!res) return res;

        const keys = Object.keys(res);
        if (keys.length === 1) {
          res = res[keys[0]];
        }
        if ("id" in res) {
          return data.update(data => ({...data, [name]: {...data[name], ...{[res.id]: res}}}))
        }
        data.update(data => ({...data, [name]: res}))
      })
      .createEffect();

    return actionQL;
  }


  /// Mutation Action
  if (operation === "mutation") {
    // console.log("@@@@@@@@@@@@@@@@@@@@@@@@", variableDefinitions);

    const mutation = gql(query.query);
    on(actionQL)
      .map(filterVariableInputType(variableDefinitions))
      .exhaustMap(variables => dispatch(actionQL.REQUEST,
        client.mutate({mutation, variables}).catch(graphqlErrorHandler), variables)
        .pipe(pipe))
      .createEffect();

    return actionQL;
  }
}


/// @FIXME:
const parseType = (type) => type.type ? [...(parseType(type.type)), type.kind] : [type.name.value];
const parseField = (field) => [field.name.value, ...parseType(field.type)];
const parseVariable = (field) => [field.variable.name.value, ...parseType(field.type)];

action_GraphQL.schema = (schema) => {

  // @TODO: Parse schema to auto validate!!!!
  const _schema = schema;

  const __schema = _schema.filter(typeDef => typeDef.kind.endsWith("ObjectTypeDefinition")).map(typeDef => [typeDef.name.value, typeDef.fields.map(parseField)])

  for (const typeDef of __schema) {
    const [key, fields] = typeDef;

    const type = Type[key] = {};
    for (const field of fields) {
      const [key, ...def] = field;
      type[key] = def
    }
  }
}


