import {Observable} from "../observable"

Observable.enableLog = true

Observable.prototype.todo = function (message) {
  return this.tap(value => console.log("\x1b[43m" + "@TODO: " + message, value))
}

export const select = (...args) => args.reduce((a, b) => a && a[String(b)])

export * from "./action.js"
export * from "./writable.js"