<script>
import {dispatch, on} from "1px";
import Validator from "../../../components/utils/Validator.svelte";
import {select} from "../../../1px/dataLayer";
import {__cloneObject} from "../../../1px/fp";
import {createDateFormat} from "../../../1px/fp/date.js";
import {store} from "../../../store/store.js";
import {_BACK, onRoute} from "../../../1px/plugins/router.js";
import {_스케줄_저장하기, _스케줄_편집_취소하기} from "../../../store/actions.js";
import {_QUERY_USERS_IN_COMPANY, _SAVE_SCHEDULE} from "../../../store/features/schedule/store.schedule.js";

import PEI_LayoutMain from "../../../components/organisms/PEI_LayoutMain.svelte";
import PEI_ButtonFill from "../../../components/atoms/PEI_Button/PEI_ButtonFill.svelte";
import PEI_TitleBar from "../../../components/atoms/PEI_Bar/PEI_TitleBar.svelte";
import PEI_ListRowField from "../../../components/molecules/PEI_List/PEI_ListRowField.svelte";
import Icon__save from "../../../components/atoms/icons/Icon__save.svelte";
import Icon__cancel from "../../../components/atoms/icons/Icon__cancel.svelte";
import PEI_ListRowFieldTextarea from "../../../components/molecules/PEI_List/PEI_ListRowFieldTextarea.svelte";
import Icon__schedule from "../../../components/atoms/icons/Icon__schedule.svelte";
import PEI_ListRowFieldCheckbox from "../../../components/molecules/PEI_List/PEI_ListRowFieldCheckbox.svelte";
import UITimePicker from "../../../components/ui/UITimePicker.svelte";
import UIDatePicker from "../../../components/ui/UIDatePicker.svelte";
import {Subject} from "../../../1px/observable";
import {data} from "../../../1px/plugins/graphql.js";
import {_QUERY_SCHEDULE_BY_ID_EDIT, _QUERY_USERS_IN_TEAM} from "../../../store/features/schedule/store.schedule.js";
import UIPage from "../../../components/ui/UIPage.svelte";
import {_QUERY_SCHEDULE_BY_ID} from "../../../store/features/schedule/store.schedule.js";


const dateFormat = createDateFormat("yyyy년 mm월 dd일");
const account = store.account$;

const 스케줄_저장버튼_클릭 = (schedule, validate) => dispatch(validate(_SAVE_SCHEDULE(schedule)));
const 취소버튼_클릭 = () => dispatch(_스케줄_편집_취소하기());

on(_SAVE_SCHEDULE.SUCCESS)
  .tap(() => alert('저장하였습니다.'))
  .dispatch(_BACK());

on(_SAVE_SCHEDULE.FAILURE)
  .tap(error => alert(error.message))
  .createEffect();

on(_스케줄_편집_취소하기)
  .filter(() => confirm("취소하시겠습니까?"))
  .dispatch(_BACK());


export let id = null;
export let start_datetime = null;

let projects = [];
let users = [];
let users_all = [];
let selected_project = {name: "select project", team: {name: ""}};
let subject = new Subject();

/// @TODO: 빈 오브젝트 채워주는 기능 구현할것!!!
let schedule = null;

data
$: dispatch(_QUERY_SCHEDULE_BY_ID(id))
$: dispatch(_QUERY_SCHEDULE_BY_ID_EDIT())

on(_QUERY_SCHEDULE_BY_ID.SUCCESS)
  .map(data => __cloneObject(data.schedule))
  .map(schedule => id ? schedule : ({
    ...schedule,
    is_individual: false,
    start_datetime: new Date(start_datetime),
    end_datetime: new Date(start_datetime),
  }))
  .createEffect(value => schedule = value)


/// add schedule에서 담당자를 불러올때 가나다순으로 불러오고 자신이 우선 선택되어 있게
on(_QUERY_USERS_IN_TEAM.SUCCESS)
  .withLatestFrom(account)
  .tap(([users, account]) => schedule.in_charge.id = schedule.in_charge.id || account.id)
  .createEffect()

data

$: projects = select($data, _QUERY_SCHEDULE_BY_ID_EDIT) || projects;
$: if (schedule && schedule.project) selected_project = projects.find(project => project.id === schedule.project.id) || selected_project
$: if (selected_project.team.id) subject.next(selected_project.team.id)
subject.distinctUntilChanged().dispatch(team_id => _QUERY_USERS_IN_TEAM(team_id))

$: users = $data[_QUERY_USERS_IN_TEAM] || users;


account
  .filter(account => account && account.id)
  .dispatch(account => _QUERY_USERS_IN_COMPANY(account.companies.map(company => ({id: company.id}))))

$: users_all = select($data, _QUERY_USERS_IN_COMPANY) || users_all;
</script>


<UIPage {...$$props}>
  <PEI_LayoutMain>
    <PEI_TitleBar text="schedule">
      <Icon__schedule/>
    </PEI_TitleBar>

    {#if schedule}
      <Validator let:validate={validate}>
        <section class="pei-list">
          <div class="list__item">
            <PEI_ListRowField field="제목 *" status="editable" value theme error={validate(!schedule.title, '제목을 입력하세요')}>
              <input type="text" bind:value={schedule.title}/>
            </PEI_ListRowField>

            <PEI_ListRowFieldCheckbox field="구분" status="editable" bind:checked={schedule.is_individual}>개인 스케줄</PEI_ListRowFieldCheckbox>

            {#if !schedule.is_individual}
              <PEI_ListRowField field="프로젝트 *" status="editable" value theme error={validate(() => !schedule.is_individual && !schedule.project.id, '프로젝트를 선택해주세요.')}>
                <select bind:value={schedule.project.id}>
                  <option value={null}>-- 프로젝트 선택 --</option>
                  {#each projects as project}
                    <option value={project.id}>{project.name} / {project.team.name}</option>
                  {/each}
                </select>
              </PEI_ListRowField>
            {/if}

            {#if !schedule.is_individual || schedule.project.id}
              <PEI_ListRowField field="담당자 *" status="editable" value theme error={validate(() => (!schedule.is_individual || schedule.project.id) && !schedule.in_charge.id, '담당자를 선택해주세요.')}>
                <select bind:value={schedule.in_charge.id}>
                  <option value={null}>-- 담당자 선택 --</option>
                  {#each users as user}
                    <option value={user.id}>{user.name}</option>
                  {/each}
                </select>
              </PEI_ListRowField>

            {:else if schedule.is_individual}
              <PEI_ListRowField field="담당자" status="editable" value theme>
                <select bind:value={schedule.in_charge.id}>
                  <option value={null}>-- 담당자 선택 --</option>
                  {#each users_all as user}
                    <option value={user.id}>{user.name} / {user.email || '-'}</option>
                  {/each}
                </select>
              </PEI_ListRowField>
            {/if}

            <PEI_ListRowField field="시작일" status="editable" value theme>
              <UIDatePicker bind:value={schedule.start_datetime}/>
            </PEI_ListRowField>

            <!--  @NOTE: 추후에 다시 개발 예정  -->
            <!--          <PEI_ListRowFieldCheckbox field="종료일 지정" status="editable" bind:checked={schedule.has_end_datetime}/>-->

            <!--          {#if schedule.has_end_datetime}-->
            <!--            <PEI_ListRowField field="종료일" status="editable" value theme>-->
            <!--              <UIDatePicker bind:value={schedule.end_datetime}/>-->
            <!--            </PEI_ListRowField>-->
            <!--          {/if}-->

            <PEI_ListRowFieldCheckbox field="시간 미지정" status="editable" bind:checked={schedule.has_notime}/>

            {#if !schedule.has_notime}
              <PEI_ListRowField field="시작시각" status="editable" value theme>
                <UITimePicker bind:value={schedule.start_datetime}/>
              </PEI_ListRowField>

              <PEI_ListRowField field="종료시각" status="editable" value theme>
                <UITimePicker bind:value={schedule.end_datetime}/>
              </PEI_ListRowField>
            {/if}

            <!--				<PEI_ListRowField field="상태" status="editable">-->
            <!--					<select bind:value={schedule.state}>-->
            <!--						<option value="">진행중</option>-->
            <!--						<option value="">대기</option>-->
            <!--						<option value="">완료</option>-->
            <!--					</select>-->
            <!--				</PEI_ListRowField>-->

            <PEI_ListRowFieldCheckbox field="형광펜" status="editable" bind:checked={schedule.is_important_event}>중요이벤트</PEI_ListRowFieldCheckbox>

            <PEI_ListRowFieldTextarea field="내용" status="editable">
              <div class="textarea-box">
                <textarea class="textarea" bind:value={schedule.desc}></textarea>
              </div>
            </PEI_ListRowFieldTextarea>
          </div>

          <section inset>
            <PEI_ButtonFill type="save" text="save" on:click={() => 스케줄_저장버튼_클릭(schedule, validate)}>
              <Icon__save/>
            </PEI_ButtonFill>

            <PEI_ButtonFill theme="mute" text="back" on:click={취소버튼_클릭}>
              <Icon__cancel/>
            </PEI_ButtonFill>
          </section>
        </section>
      </Validator>
    {/if}
  </PEI_LayoutMain>
</UIPage>