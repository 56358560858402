<script>
export let value;
</script>

<div class="search-bar">
	<input class="input" type="text" required on:input bind:value/>
	<div class="search-placeholder">
		<div>
			<svg width="1.2rem" height="1.2rem" viewBox="0 0 12 12">
				<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
					<g id="3-1-Notice" transform="translate(-166.000000, -116.000000)" fill="#999999">
						<g id="filter" transform="translate(0.000000, 74.000000)">
							<g id="search" transform="translate(12.000000, 29.000000)">
								<path d="M158.681361,21.5165957 C160.692262,21.5165957 162.32242,19.8484039 162.32242,17.7905851 C162.32242,15.7327662 160.692262,14.0645745 158.681361,14.0645745 C156.67046,14.0645745 155.040302,15.7327662 155.040302,17.7905851 C155.040302,19.8484039 156.67046,21.5165957 158.681361,21.5165957 Z M158.681361,13 C161.266805,13 163.362722,15.144818 163.362722,17.7905851 C163.362722,18.7771277 163.071313,19.6940201 162.571777,20.4560388 C162.586171,20.4665581 162.600468,20.4799077 162.614393,20.4938863 C162.614393,20.4938863 166,23.8923894 166,23.8923895 C166,23.8923896 164.896592,24.9999998 164.896593,25 L164.801796,24.904842 C164.258406,24.3593823 161.561582,21.6522856 161.511687,21.6022009 C160.727067,22.2176952 159.745693,22.5811702 158.681361,22.5811702 C156.095917,22.5811702 154,20.4363522 154,17.7905851 C154,15.144818 156.095917,13 158.681361,13 Z"
									id="icon-search"></path>
							</g>
						</g>
					</g>
				</g>
			</svg>
			검색
		</div>
	</div>
</div>

<style type="text/stylus">
.search-bar
	height 100%

	.search-placeholder
		position absolute
		width 100%
		height 100%
		top 0
		left 0
		text-align center
		font-size 1.4rem
		color var(--search-bar__placeholder)
		hbox()

		> *
			flex()

	input
		display block
		width 100%
		height 100%
		text-align center

.search-placeholder
	pointer-events none

.input:focus
	text-align center

.input:focus + .search-placeholder
.input:valid + .search-placeholder
	display none

</style>