<script>
import Icon__planit from "../atoms/icons/Icon__planit.svelte";
import Icon__element from "../atoms/icons/Icon__element.svelte";

export let company
</script>

<icon class="row__icon">
  {#if company.name === 'element' || company.name === 'element_pictures'}
    <Icon__element/>
  {:else if company.name === 'planit' || company.name === 'planit_production'}
    <Icon__planit/>
  {/if}
</icon>