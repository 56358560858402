<script>
import Icon__planit from "../../atoms/icons/Icon__planit.svelte";
import Icon__element from "../../atoms/icons/Icon__element.svelte";
import {createDateFormat} from "../../../1px/fp/date";

export let user = {};

</script>


<section class="list__row hbox--top" on:click>
  <!--  <div>-->
  <!--		<Icon__planit/>-->
  <!--		<Icon__element/>-->
  <!--  </div>-->

  <!--  <space size="1"/>-->

  <div class="flex">
    <div class="content__caption hbox">
      <div class="caption__name flex">
        <span class="name__proj"><strong>{user.name || '-'}</strong> / {user.email || '-'}</span>
      </div>
      <div class="caption__date">{user.company && user.company.name || '-'}</div>
    </div>

    <div class="content__title">{user.companies && user.companies.length && user.companies.map(c => c.name).join(", ") || '-'}</div>

    <div class="content__title">{user.job} / {user.teams && user.teams.length && user.teams.map(team => team.name).join(", ") || '-'}</div>
  </div>
</section>


<style type="text/stylus">
.list__row
  font-size 1.4rem
  padding .6rem 1.2rem
  border-bottom 1px dashed $light-gray
  cursor pointer

.content__caption
  height 2.4rem

.caption__name
  height 100%
  overflow hidden

.caption__date
  color $gray
  font-weight 700

.content__title
  line-height 2.4rem
  text-align Justify

.list__row:last-of-type
  border-bottom 0

</style>