<script>
export let value;
export let type = "";
export let name = "";
export let options = [];

let team_jobs = ['엘리먼트','플랜잇','플랜잇','플랜잇','엘리먼트','엘리먼트','정성원','최민표','황인규','정중화','윤동욱','김형균','차유현','플랜잇','엘리먼트'];
</script>


<pei-select>
  <select class={type} on:input bind:value>
    <option class="select-option__cover" value={null}>{name}</option>
    <slot/>

    {#if type != 'more-info'}
      {#each options as option}
        <option value={option.id}>{option.name}</option>
      {/each}
    {:else if type == 'more-info'}
      {#each options as option, index}
        <option value={option.id}>{option.name}({team_jobs[index]})</option>
      {/each}
    {/if}
  </select>
  <div class="select-arrow {type}"></div>
</pei-select>


<style type="text/stylus">
pei-select
  position relative

  select
    -moz-appearance none
    -webkit-appearance none
    appearance none

    &.emphasised
      font-weight bold
      text-align center
      text-align-last center

  .select-option__cover
    text-decoration uppercase

  .select-arrow
    position absolute
    top 1rem
    right .4rem
    width 0
    height 0
    border-left .5rem solid transparent
    border-right .5rem solid transparent
    border-top .5rem solid $black

    &.emphasised
      right 0
</style>