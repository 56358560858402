<script>
import {Observable} from "../1px/observable";

let log = ""
let show = true
let el;


var getStackTrace = function() {
  var obj = {};
  Error.captureStackTrace(obj, getStackTrace);
  return obj.stack;
};



Observable.hostErrorHandler(error => {
  el.innerText += error.stack + "\n"
  el.classList.remove("none")
})

window.onunhandledrejection = error => {
  el.innerText += error.reason.stack + "\n"
  el.classList.remove("none")
}
const hide = () => {
  el.classList.add("none")
}
</script>


<section class="error-log pre layer fixed none" on:click={hide} bind:this={el}>
  {log}
</section>


<style lang="stylus">
.error-log
  background rgba(#000, .3)
  color #fff
  z-index 9999999

</style>